import { UserFile, HeaderType } from '../Types'
import { mergeSheets, getHeaders } from './Merge'
import { sort } from './Sort'
const checkComma = (s: string) => {
  if (typeof(s) !== 'string') {
    return true
  }
  if (s.length < 2) {
    return true
  }
  const a = s.slice(0,1)
  const b = s.slice(1,2)
  if (a === '0') {
    if (b === ',' || b === '.') {
      return true
    }
    return false
  }
  return true
}
// TODO this is also in merge.ts move to common utils file
const isNumber = (n: string) => !isNaN(Number(n)) && n !== null && n !== '' && checkComma(n)

export const groupby = (userFiles: UserFile[], header: string): UserFile[] => {
  const sheets = mergeSheets(userFiles)
  const f: UserFile = {
    kind: 'CSV',
    parsedData: sheets,
    name: 'merged.csv',
    headers: getHeaders(sheets),
    originalData: '',
    size: '',
    stats: []
  }
  const sortedHeaders = f.headers
  const headerColumn = sortedHeaders.getColumn[header]
  const headerRow = sortedHeaders.line
  const headerType = sortedHeaders.headerTypes[headerColumn]
  const ht = (ht: HeaderType): 'ALPHABET' | 'NUMBER' => {
    switch(ht) {
      case 'NUMBER': return 'NUMBER'
      case 'STRING': return 'ALPHABET'
    }
  }
  const sortedSheet = sort(f, header, ht(headerType)).parsedData

  // Safety check
  if (sortedSheet.length < headerRow+2) {
    return [f]
  }
  const datat: { [i: number]: string[][] } = {}
  let current: string | number = sortedSheet[headerRow+1][headerColumn]
  current = isNumber(current) ? Number(current) : current
  let n = 1
  for (let j = headerRow+2; j < sortedSheet.length; ++j) {
    let v: string | number = sortedSheet[j][headerColumn]
    v = isNumber(v) ? Number(v) : v
    if (v !== current) {
      if (typeof(datat[n]) === 'undefined') {
        datat[n] = sortedSheet.slice(j-n, j)
      } else {
        datat[n] = datat[n].concat(sortedSheet.slice(j-n, j))
      }
      current = v
      n = 1
    } else {
      n += 1
    }
  }
  if (typeof(datat[n]) === 'undefined') {
    datat[n] = sortedSheet.slice(sortedSheet.length-n, sortedSheet.length)
  } else {
    datat[n] = datat[n].concat(sortedSheet.slice(sortedSheet.length-n, sortedSheet.length))
  }

  return Object.keys(datat).map(k => {
    const uf: UserFile = {
      kind: 'CSV',
      name: `${k}.csv`,
      parsedData: [sortedHeaders.headers, ...datat[k]],
      headers: sortedHeaders,
      size: '',
      originalData: '',
      stats: []
    }
    return uf
  })
  
}