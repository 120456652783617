import { UserFile, ColumnStats } from '../Types'

export const getStats = (sorted: string[]): {mode: string, shortest: string, longest: string} | undefined => {
  let modeCount = 0
  let modeIndex = 0

  let shortestLength = 0
  let shortestIndex = 0
  
  let longestLength = 0
  let longestIndex = 0

  let prev = undefined
  let count = 0
  for (let i = 0; i < sorted.length; ++i) {
    const v = sorted[i]
    if (shortestLength > v.length) {
      shortestLength = v.length
      shortestIndex = i
    }
    if (longestLength < v.length) {
      longestLength = v.length
      longestIndex = i
    }
    if (prev === v) {
      count += 1
    } else {
      count = 0
    }

    if (count > modeCount) {
      modeIndex = i-1
      modeCount = count
    }
    prev = sorted[i]
  }
  return { 
    mode: sorted[modeIndex], 
    shortest: sorted[shortestIndex], 
    longest: sorted[longestIndex]
  }
}

type Column = {
  header?: string 
  values: string[]
}
const updateStateWithHeader = (columns: Column[], line: string[]): Column[] => {
  for (let i = 0; i < line.length; ++i) {
    const c = columns[i] ? columns[i] : ({ header: undefined, values: [] })
    if (typeof(c['header']) === 'undefined') {
      if (line[i] === '') {
        continue
      } else {
        c['header'] = line[i]
      }
    } else {
      if (line[i] === '') {
        continue
      } else {
        c.values.push(line[i])
      }
    }
  }
  return columns
}

const updateStateWithoutHeader = (columns: Column[], line: string[]): Column[] => {
  for (let i = 0; i < line.length; ++i) {
    const c = columns[i] ? columns[i] : ({ header: undefined, values: [] })
    if (line[i] === '') {
      continue
    } else {
      c.values.push(line[i])
    }
  }
  return columns
}

export const statsToFile = (header: boolean) => (f: UserFile): UserFile => {
  const cs: ColumnStats[] = f.parsedData.reduce(header ? updateStateWithHeader : updateStateWithoutHeader, 
    Array.from(Array(f.parsedData.length)).map(() => 
      ({ header: undefined, values: []})
    )
  )
  .filter(a => a.values.length > 0)
  .map(c => {
    const sorted = c.values.sort((a,b) => a.localeCompare(b))
    const sts = getStats(sorted)

    return ({ header: c.header, 
      median: sorted[Math.floor(sorted.length/2)],
      mode: sts ? sts.mode : '',
      longest: sts ? sts.longest : '',
      shortest: sts ? sts.shortest : '',
      cardinality: sorted.length
    })
  })

  return {...f, stats: cs}
}