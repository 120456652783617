import React from 'react'
import { State, Page, UserFile, GroupbyPage, ToolPage } from '../../../Types'
import { ContentMaxWidth, toolToColor } from '../../../Constants'
import { Header } from '../../Header'
import { writeCsv } from '../../../libs/Download'
import { groupby } from '../../../libs/Groupby'
import { headerIntersection } from '../../../libs/Merge'
import {
  Link,
} from "react-router-dom"
import { MyDropzone } from '../DataDrop'

type Props = {
  updateState: (s: State<Page>) => void
  state: State<GroupbyPage>
}

const topContent = (props: Props) => {
  const headers = headerIntersection(props.state.currentPage.userFiles)
  const tool = props.state.currentPage.kind
  const disabled = props.state.currentPage.undo?.kind === 'GROUPBY' ? true : false
  return (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column'
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <div
        style={{
          fontSize: '22px',
          fontWeight: 700,
          color: 'grey',
          marginRight: '10px'
        }}
        >{"Group rows by how many duplicates there are in the column."}
      </div>
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <div
        style={{
          fontSize: '22px',
          fontWeight: 700,
          color: 'black',
          marginRight: '10px'
        }}
        >{"Choose header:"}
      </div>
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
      }}
    >
      {headers.map((h,key) => {
        return (
        <div
          key={key}
          className={"noselect"}
          style={{
            fontSize: '22px',
            fontWeight: 700,
            color: disabled ? 'grey' : toolToColor(tool),
            marginRight: '10px',
            cursor: disabled ? 'auto' : 'pointer',
          }}
          onClick={() => {
            if (!disabled) {
              props.updateState({
                ...props.state,
                currentPage: {
                  ...props.state.currentPage,
                  userFiles: groupby(props.state.currentPage.userFiles, h),
                  undo: props.state.currentPage
                }
              })
            }
          }}
          >
          {h}
        </div>
        )
      })}
    </div>

  </div>)
}
export const Groupby = (props: Props) => {
  const undoable = typeof(props.state.currentPage.undo) !== 'undefined'
  const highlightColor = toolToColor(props.state.currentPage.kind)
  return (
    <div
      style={{
        width: ContentMaxWidth
      }}
    >
      <Header state={props.state} updateState={props.updateState} />
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '50px'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginBottom: '15px'
          }}
        >
          {topContent(props)}
          <div style={{flex: 1}} />
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end'
            }}
          >
            {undoable ? (
              [<div
                key={1}
                style={{
                  fontSize: '22px',
                  color: highlightColor,
                  fontWeight: 700,
                  marginLeft: '2px',
                  marginRight: '10px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  if (typeof(props.state.currentPage.undo) !== 'undefined') {
                    props.updateState(
                      {...props.state, currentPage: props.state.currentPage.undo}
                    )
                  }
                }}
              >
                Undo
              </div>,
              <div key={2} style={{
                borderRight: '1px solid',
                borderColor: 'grey',
                height: '35px'
              }}></div>
            ]
            ) : null }
            <div
              style={{
                fontSize: '22px',
                color: highlightColor,
                fontWeight: 700,
                marginLeft: '10px',
                cursor: 'pointer',
              }}
              onClick={() => {
                writeCsv(props.state.currentPage.userFiles)
              }}
            >
              Download
            </div>
          </div>
        </div>
        <span
          style={{
            width: '100%',
            borderBottom: '1px solid',
            borderColor: 'black'
          }}
        />
        {props.state.currentPage.userFiles.map((f: UserFile, key: number) => {
          return (
            <div
              key={key}
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%'
                }}
              >
                <input
                  type="text"
                  value={f.name}
                  onChange={(e) => {
                    props.updateState({
                      ...props.state,
                      currentPage: {
                        ...props.state.currentPage,
                        userFiles: [
                          ...props.state.currentPage.userFiles.slice(0,key),
                          {
                            ...props.state.currentPage.userFiles[key],
                            name: e.target.value
                          },
                        ...props.state.currentPage.userFiles.slice(key + 1,props.state.currentPage.userFiles.length)
                        ]
                      }
                    })
                  }}
                  placeholder={""}
                  style={{
                  width: '90%',
                  fontSize: '18px',
                  fontWeight: 700,
                  lineHeight: '38px',
                  border: 0,
                  outlineWidth: 0
                  }}
                >
                </input>
                <div style={{flex: 1}}></div>
                <Link
                  to={"/groupby/view"}
                  style={{ textDecoration: 'none' }}
                >
                  <div
                      onClick={() => {
                        props.updateState({
                          currentPage: {
                            kind: 'TABLE',
                            userFile: f,
                            highlights: [],
                            highlightColor: highlightColor
                          },
                          prevPage: props.state.currentPage,
                          sidemenuOpen: false
                        })
                      }}
                      style={{
                        marginTop: '0.83em',
                        marginBottom: '0.83em',
                        fontSize: '22px',
                        color: highlightColor,
                        fontWeight: 700,
                        cursor: 'pointer'
                      }}>
                        View
                    </div>
                  </Link>
              </div>
              <span
              style={{
                width: '100%',
                borderBottom: '1px solid',
                borderColor: 'grey'
              }}
              />
          </div>
          )
        })}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '80px',
          marginBottom: '80px'
        }}
      >
        <div
          style={{
            width: '600px',
            height: '450px'
          }}
        >
          <MyDropzone
            state={props.state}
            stateChange={(s: State<ToolPage>) => {
              props.updateState({
                ...props.state,
                currentPage: {
                  ...props.state.currentPage,
                  userFiles: [...props.state.currentPage.userFiles, ...s.currentPage.userFiles]
                }
              })
            }}
            textSize={'28px'}
            tool={'GROUPBY'}
          />
        </div>
      </div>
    </div>
  )
}