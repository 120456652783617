
import * as React from 'react'
import {AllTools, State, Page} from '../Types'
import { toolToColor, toolToName } from 'src/Constants'
import {
  Link,
} from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { doSearch } from '../LandingPage/DataDrop/Search/Search'
import { toolToEvent, fireEvent } from '../libs/mixpanel'

type Props = {
  state: State<Page>
  updateState: (s: State<Page>) => void
}

const numberOfFiles = (page: Page) => {
  switch (page.kind) {
    case 'DATADROP': return 0
    case 'LANDINGPAGE': return 0
    case 'CONCAT': return page.userFiles.length
    case 'SEARCH': {
      const groupedOccurrences = doSearch(page)
      return groupedOccurrences ? Object.keys(groupedOccurrences).length : page.userFiles.length
    }
    case 'SELECT': return page.userFiles.length
    case 'SPLIT': return page.userFiles.length
    case 'STATS': return page.userFiles.length
    case 'TABLE': return 0
    case 'TOXLSX': return page.userFiles.length
    case 'MERGE': return page.mergedFile ? 1 : page.userFiles.length
    case 'SORT': return page.userFiles.length
    case 'GROUPBY': return page.userFiles.length
    case 'COPY': return page.userFiles.length
    case 'CALC': return page.userFiles.length
  }
}
export const Sidemenu = (props: Props) => {
  const n = numberOfFiles(props.state.currentPage)
  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 999,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
        onClick={() => {
          props.updateState({...props.state, sidemenuOpen: false})
        }}
      >

      </div>
      <div style={{
        width: '300px',
        height: '100%',
        position: 'fixed',
        backgroundColor: 'white',
        top: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '2px 2px 2px 2px black',
        overflow: 'auto'
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '70px',
          marginLeft: '20px'
        }}
      >
        <div
          style={{
            cursor: 'pointer'
          }}
          onClick={() => {
            props.updateState({...props.state, sidemenuOpen: false })
          }}
        >
          <FontAwesomeIcon icon={faTimes} size={'2x'} color={'black'} />
        </div>
      </div>
      <div
        style={{
          height: '150px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            fontSize: '22px',
            width: '250px',
          }}
        >
          {"Move (" + n + ') files to another tool:'}
        </div>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        flex:1
      }}>
        {AllTools.map((t,key) => (
          <Link
            key={key}
            to={`/${t.toLocaleLowerCase()}`}
            style={{
              textDecoration: 'none',
              display: 'flex',
              flexDirection: 'row'
            }}
            onClick={() => {
              fireEvent(toolToEvent(t))
            }}
          >
            {props.state.example === 'MOVE_TO_CONCAT' && t === 'CONCAT'
              ? (<div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 700,
                    fontSize: '30px',
                    height: '70px',
                    marginRight: '2px',
                    color: toolToColor('SELECT')
                  }}
                >
                  ->
                </div>)
              : null}
            <div style={{
              width: '250px',
              height: '50px',
              marginBottom: '15px',
              backgroundColor: toolToColor(t),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }}>
              <h3 style={{color: 'white'}}>{toolToName(t)}</h3>
            </div>

          </Link>
        ))}
      </div>

      </div>
    </div>
  )
}