import { Headers } from '../Types'

export const Calc = (
  sheet: string[][], 
  header: Headers, 
  calc: string,
  columnIndex: number
  ): string[][] => {  
    if (sheet.length < 1) {
      return sheet
    }

    for (let j = header.line + 1; j < sheet.length; ++j) {
      const tmp: any = sheet[j][columnIndex]
      if (!isNaN(tmp) && tmp !== '' && tmp !== null) {
        // Is number
        const n = Number(tmp)
        try {
          const val = eval(`${n} ${calc}`)
          if (typeof(val) === 'number') {
            sheet[j][columnIndex] = String(val)
          } else {
            sheet[j][columnIndex] = tmp
          }
        } catch {
          sheet[j][columnIndex] = tmp
        }
      }
    }
    return sheet
}