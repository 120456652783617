import { UserFile } from 'src/Types';

export const dropColumns = (uf: UserFile[], headers: { [header: string]: boolean}) => {
  return uf.map(f => {
    if (f.parsedData.length > 0) {
      const nToRemove = Object.values(headers).filter(a => !a).length

      const newData = Array(f.parsedData.length)
        .fill(null)
        .map(() => 
          Array(f.parsedData[0].length - nToRemove)
            .fill(null)
        )
      
      let n = 0
      const columnsToRemove = []

      for (let i = 0; i < f.parsedData[0].length; ++i) {
        for (let j = 0; j < f.parsedData.length; ++j) {
          const v = f.parsedData[j][i]
          if (v !== '') {
            if (typeof(headers[v]) === 'boolean' && !headers[v]) {
              columnsToRemove[i] = true
            }
            break
          }
        }
      }
      for (let i = 0; i < f.parsedData[0].length; ++i) {
        if (columnsToRemove[i]) {
          continue
        }
        for (let j = 0; j < f.parsedData.length; ++j) {
          newData[j][n] = f.parsedData[j][i]
        }
        n += 1
      }
      return {...f, parsedData: newData}
    } else {
      return f
    }
  })
}




export const getHeaders = (uf: UserFile[]): { [header: string]: boolean } => {

  const onlyUnique = (value: string, index: number, self: any) => { 
    return self.indexOf(value) === index;
  }

  const headers: string[] = []
  
  uf.forEach(f => {
    
    if (f.parsedData.length > 0) {
      for (let i = 0; i < f.parsedData[0].length; ++i) {
        for (let j = 0; j < f.parsedData.length; ++j) {
          const v = f.parsedData[j][i]
          if (v !== '' && v !== undefined) {
            headers.push(v)
            break
          }
        }
      }
    }
  })

  const obj: { [header: string]: boolean } = {}
  
  headers.filter(onlyUnique).forEach(s => {
    obj[s] = true
  })

  return obj
}
