import { Tool } from './Types'

export const AppName = 'Blocksheet'
export const Email = 'blocksheet.io@gmail.com'
export const Domain = 'blocksheet.io'
// Old He-Man tutorial video >_<
// export const TutorialVideo = 'https://www.youtube.com/watch?v=ZZ5LpwO-An4'
export const TutorialVideo = 'https://youtu.be/xuqNsQGDHEs'

export const FrontPageCopyTexts = {
  first: 'Your spreadsheet toolbox',
  second: 'Just click the tool you want',
  third: 'and drop in your files.'
}

export const Colors = {
  darkOrange: '#e76f51',
  orange: '#f4a261',
  yellow: '#e9c46a',
  green: '#2a9d8f',
  darkGreen: '#264653',
  red: '#ff6b6b'
}

export const ContentMaxWidth = '1220px'

// red here: https://coolors.co/1a535c-4ecdc4-f7fff7-ff6b6b-ffe66d
// rest here: https://coolors.co/264653-2a9d8f-e9c46a-f4a261-e76f51

export const toolToColor = (t: Tool): string => {
  switch (t) {
    case 'SEARCH': return Colors.darkOrange
    case 'CONCAT': return Colors.orange
    case 'SPLIT': return Colors.green
    case 'TOXLSX': return Colors.yellow
    case 'STATS': return Colors.darkGreen
    case 'SELECT': return Colors.red
    case 'MERGE': return '#F6A585'
    case 'SORT': return '#A6B588'
    case 'GROUPBY': return '#77999D'
    case 'COPY': return '#683E2D'
    case 'CALC': return '#58ACAF'// '#D3DDC9'
  }
}

export const toolToName = (t: Tool): string => {
  switch (t) {
    case 'SEARCH': return 'Search'
    case 'CONCAT': return 'Concat'
    case 'SPLIT': return 'Split'
    case 'TOXLSX': return 'To XLSX'
    case 'STATS': return 'Stats'
    case 'SELECT': return 'Select'
    case 'MERGE': return 'Merge'
    case 'SORT': return 'Sort'
    case 'GROUPBY': return 'Group by'
    case 'COPY': return 'Copy'
    case 'CALC': return 'Formula'
  }
}