import * as Papa from 'papaparse';

export const parse = (content: string): string[][] => {
    const delimiter = { kind: 'AUTO', value: undefined }
    const p: Papa.ParseResult = Papa.parse(content, {dynamicTyping: false, delimiter: delimiter.value})
    // Actually Array<any> so conversion happening, should be string | number | boolean [][]
    const longestRow = p.data.reduce( (acc, c) => c.length > acc ? c.length : acc, 0)
    const appendedData = p.data.map(r => {
      if (r.length < longestRow) {
        const n = longestRow - r.length
        return [...r, ...Array(n).fill('')]
      }
      return r
    })
    return appendedData
}
