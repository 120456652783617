
export function listInsertBeginning<T>(e: T) {
    return function<T>(list: T[]) {
        return [e, ...list]
    }
}

export function listInsertEnd<T>(e: T) {
    return function<T>(list: T[]) {
        return [...list, e]
    }
}

export function listInsertIndex<T>(e: T) {
    return function<T>(list: T[], index: number) {
        return [
            ...list.slice(0, index),
            e,
            ...list.slice(index)
          ]
    }
}

export function chunkIt<T>(xs: T[], chunkSize: number): T[][] {
  const ret: T[][] = []
  for (let i=0, j=xs.length; i < j; i += chunkSize) {
      const temparray = xs.slice(i, i + chunkSize)
      ret.push(temparray)
  }
  return ret
}

export function splitByRow<T>(xs: T[][], n: number): T[][][] {
  const tmp = []
  for (let i = 0; i < xs.length; i += n) {
    const t = xs.slice(i, i + n)
    tmp.push(t)
  }
  return tmp
}

export function splitByColumn<T>(xs: T[][], n: number): T[][][] {
  if (xs.length < 1) {
    return []
  }
  const tmp: T[][][] = []
  for (let i = 0; i < xs[0].length; i += n) {
    const r: T[][] = []
    xs.forEach(l => {
      const t = l.slice(i, i+n)
      r.push(t)
    })
    tmp.push(r)
  }
  return tmp
}

type Occurrence = { file: number, x: number, y: number}
export function groupOccurrences(
  occurrences: Occurrence[]
  ) {
  return occurrences.reduce( (acc: { [key: number]: Occurrence[]}, cur) => {
    if (acc[cur.file]) {
      acc[cur.file] = acc[cur.file].concat([cur])
      return acc
    }
    acc[cur.file] = [cur]
    return acc
  },{})
}