
import React, {useCallback} from 'react'
import { State, DataDropPage, UserFile, Page, Tool, ToolPage} from '../../Types'
import { ContentMaxWidth, toolToColor } from '../../Constants'
import { Header } from '../Header'
import {useDropzone} from 'react-dropzone'
import { parse } from '../../libs/CsvParser'
import { fireEvent } from '../../libs/mixpanel'
import { toolToState, toolToValidator } from './StateValidations'
import XLSX from 'xlsx'
import { toolToEvent } from '../../libs/mixpanel'
import { options } from '../../libs/Dropbox'
import { getHeaders } from '../../libs/Merge'

// TODO this is duplicate fix me!
const toolToHeading = (t: Tool): string => {
  switch (t) {
    case 'SEARCH': return 'Search'
    case 'CONCAT': return 'Concatenate'
    case 'SELECT': return 'Select'
    case 'SPLIT': return 'Split'
    case 'STATS': return 'Statistics'
    case 'TOXLSX': return 'To XLSX'
    case 'MERGE': return 'Merge'
    case 'SORT': return 'Sort'
    case 'GROUPBY': return 'Group by'
    case 'COPY': return 'Copy'
    case 'CALC': return 'Formula'
  }
}

const readExcel = (file: AcceptedFile, success: (f: UserFile[]) => void, failure: (err: string) => void) => {
  const reader = new FileReader()
  reader.onload = () => {
    const readData = XLSX.read(reader.result, { type: 'binary' })
    success(Object.keys(readData.Sheets).map(k => {
      const sheet = XLSX.utils.sheet_to_csv(readData.Sheets[k])
      const data = parse(sheet)
      const name = Object.keys(readData.Sheets).length > 1
        ? `${file.name}/${k.toString()}` : file.name
      return ({
        kind: 'CSV',
        name: name,
        headers: getHeaders(data), // TODO: read these from sheet_to_json
        size: file.size.toString(),
        originalData: reader.result as string,
        parsedData: data,
        stats: []
      })
    }))
  }

  reader.onabort = () => failure("Reader aborted!")
  reader.onerror = () => failure("Reader error!")
  reader.readAsBinaryString(file.blob)

  fireEvent("Read Excel")
}

const readCsv = (file: AcceptedFile, success: (u: UserFile) => void, failure: (s: string) => void) => {
  const reader = new FileReader()
  reader.onload = () => {
    const originalData = reader.result as string
    const data = parse(originalData)
    success({
      kind: 'CSV',
      name: file.name,
      size: file.size.toString(),
      originalData: originalData,
      headers: getHeaders(data),
      parsedData: data,
      stats: []
    })
  }

  reader.onabort = () => failure("Reader aborted!")
  reader.onerror = () => failure("Reader error!")
  reader.readAsText(file.blob)

  fireEvent("Read CSV")
}

const validateAndChangeState = (
  tool: Tool,
  page: Page,
  u: UserFile[],
  stateChange: (s: State<Page>) => void) => {
  const validation = toolToValidator(tool)(u)
  if (validation.kind === 'SUCCESS') {
    const s = toolToState(tool, page, validation.result)
    stateChange(s)
  } else {
    // TODO: handle failure
  }
}

type AcceptedFile = {
  blob: Blob,
  name: string,
  size: number
}
export const handleFileDrop = (tool: Tool,
  page: Page,
  stateChange: (s: State<Page>) => void) => (acceptedFiles: AcceptedFile[]) => {
  fireEvent(toolToEvent(tool))
  let userFiles: UserFile[] = []
  let i = 0
  const n = acceptedFiles.length
  const success = (u: UserFile) => {
    i += 1
    userFiles = [...userFiles, u]
    if (i === n) {
      validateAndChangeState(tool, page, userFiles, stateChange)
    }
  }
  const successExcel = (u: UserFile[]) => {
      userFiles = [...userFiles, ...u]
      i += 1
      if (i === n) {
        validateAndChangeState(tool, page, userFiles, stateChange)
      }
    }
  const failure = (s: string) => {
    i -= 1
    if (i === n) {
      validateAndChangeState(tool, page, userFiles, stateChange)
    }
  }
  acceptedFiles.forEach((f:any) => {
    const l = f.name.split('.')
    const last = l[l.length - 1]
    switch (last) {
      case 'CSV': readCsv(f, success, failure); break
      case 'csv': readCsv(f, success, failure); break
      case 'XLSX': readExcel(f, successExcel, failure); break
      case 'xlsx': readExcel(f, successExcel, failure); break
      case 'XLS': readExcel(f, successExcel, failure); break
      case 'xls': readExcel(f, successExcel, failure); break
      default: failure('ERROR'); break
    }
  })
}
export const MyDropzone = (props:
  { state: State<Page>, stateChange: (s: State<ToolPage>) => void, tool: Tool, textSize: string }) => {

  const onDrop = useCallback((files: any) =>
    handleFileDrop(
      props.tool,
      props.state.currentPage,
      props.stateChange
    )(files.map((f: any) => ({ blob: f, name: f.name, size: f.size }))),
    []
  )
  const {getRootProps, getInputProps} = useDropzone({onDrop})

  return (
    <div style={
      {
        width: '100%',
        height: '100%',
        borderStyle: 'dashed',
        borderWidth: '12px',
        borderColor: toolToColor(props.tool),
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    } {...getRootProps()}>
      <input {...getInputProps()} />
      {
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <p
            style={{
              fontSize: props.textSize
            }}
          >
            {"Drop your .csv, .xlsx, .xls files here!"}
          </p>
          <p
          style={{
            fontSize: props.textSize,
            marginBottom: 0
          }}
          >
          {"Everything is done in your browser."}
          </p>
          <p
          style={{
            fontSize: props.textSize,
            marginTop: 0
          }}
          >
          {"Your files are not sent anywhere."}
          </p>
        </div>
      }
    </div>
  )
}

type Props = {
  stateChange: (s: State<Page>) => void
  state: State<DataDropPage>
}

export const DataDrop = (props: Props) => {
  return (
    <div
      style={{
        width: ContentMaxWidth
      }}
    >
      <Header state={props.state} updateState={props.stateChange} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'baseline',
          cursor: 'pointer',
          width: '230px'
        }}
        onClick={() => {
          // @ts-ignore
          Dropbox.choose(options(
            handleFileDrop(
              props.state.currentPage.tool,
              props.state.currentPage,
              props.stateChange
            )
          ))
        }}
      >
        <span style={{
          width: '16px',
          height: '14px',
          backgroundImage: "url('https://www.dropbox.com/static/images/widgets/dbx-saver-status.png')"
        }}></span>
        <div
          style={{
            marginLeft: '5px'
          }}
        >
          Choose from Dropbox
        </div>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <h1>{toolToHeading(props.state.currentPage.tool)}</h1>
        <div
          style={{
            width: '90%',
            height: '600px'
          }}
        >
          <MyDropzone
            state={props.state}
            stateChange={props.stateChange}
            tool={props.state.currentPage.tool}
            textSize={'34px'}
          />
        </div>
      </div>
    </div>
  )
}