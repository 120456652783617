import React from 'react'
import { State, ConcatPage, Page, UserFile, ToolPage } from '../../../Types'
import { ContentMaxWidth, toolToColor } from '../../../Constants'
import { Header } from '../../Header'
import { fireEvent } from '../../../libs/mixpanel'
import { writeCsv } from '../../../libs/Download'
import {
  Link,
} from "react-router-dom"
import { AppNotification } from '../../../AppNotification'
import { MyDropzone } from '../DataDrop'
const concatenateByColumn = (files: UserFile[]): UserFile[] => {
  if (files.length < 2) {
    return files
  }
  const f = files.reduce((prev, curr, k) => {
    const tmp: string[][] = []
    curr.parsedData.forEach((v,k) => {
      const a = prev.parsedData[k]
      const b = curr.parsedData[k]
      if (Array.isArray(a) && Array.isArray(b)) {
        const c = a.concat(b)
        tmp.push(c)
      }
    })
    const n: UserFile = ({
      ...curr,
      parsedData: tmp
    })
    return n
  }, ({...files[0], parsedData: files[0].parsedData.map(() => []) }))
  return [f]
}

const concatenateByRow = (files: UserFile[]): UserFile[] => {
  if (files.length < 2) {
    return files
  }
  const f = files.reduce((prev, curr) => {
    const d = prev.parsedData.concat(curr.parsedData)
    return {...prev, parsedData: d}
  })
  return [f]
}

type Props = {
  updateState: (s: State<Page>) => void
  state: State<ConcatPage>
}
export const Concat = (props: Props) => {
  const undoable = typeof(props.state.currentPage.undo) !== 'undefined'
  if (props.state.example === 'MOVE_TO_CONCAT') {
    props.updateState({...props.state, example: 'CONCAT_FILES'})
    fireEvent('Example moved to concat')
  }
  const highlightColor = toolToColor(props.state.currentPage.kind)
  return (
    <div
      style={{
        width: ContentMaxWidth
      }}
    >
      <Header state={props.state} updateState={props.updateState} />
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '50px'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginBottom: '15px',
            position: 'relative'
          }}
        >
          {props.state.example === 'CONCAT_FILES'
          ? (<div
              style={{
                position: 'absolute',
                left: 80,
                top: -30,
                fontSize: '20px',
                fontWeight: 700,
                color: toolToColor('SELECT')
              }}
            >
              Concatenate all the files with 'carrots' in them!
            </div>
          ) : null}
          <div
            style={{
              fontSize: '22px',
              fontWeight: 700,
              color: 'grey'
            }}
          >{"Concatenate by"}</div>
          <div
            style={{
              fontSize: '22px',
              color: highlightColor,
              fontWeight: 700,
              marginLeft: '2px',
              marginRight: '2px',
              cursor: 'pointer'
            }}
            onClick={() => {
              const changeExample = props.state.example === 'CONCAT_FILES'
              const s = {
                ...props.state,
                currentPage: {
                  ...props.state.currentPage,
                  userFiles: concatenateByColumn(props.state.currentPage.userFiles),
                  undo: props.state.currentPage
                },
                example: changeExample
                  ? 'DOWNLOAD_FILES' : props.state.example
              }
              if (changeExample) {
                fireEvent('Example concatenated files')
              }
              props.updateState(s)
            }}
          >
            {" column "}
          </div>
          <div
          style={{
            fontSize: '22px',
            fontWeight: 700,
            color: 'grey'
          }}
          >
            {"or by"}
          </div>
          <div
            style={{
              fontSize: '22px',
              color: highlightColor,
              fontWeight: 700,
              marginLeft: '2px',
              cursor: 'pointer'
            }}
            onClick={() => {
              const changeExample = props.state.example === 'CONCAT_FILES'
              const s = {
                ...props.state,
                currentPage: {
                  ...props.state.currentPage,
                  userFiles: concatenateByRow(props.state.currentPage.userFiles),
                  undo: props.state.currentPage
                },
                example: changeExample
                  ? 'DOWNLOAD_FILES' : props.state.example
              }
              if (props.state.example === 'CONCAT_FILES') {
                fireEvent('Example concatenated files')
              }
              props.updateState(s)
            }}
          >
            {" row"}
          </div>
          <div
            style={{
              fontSize: '22px',
              fontWeight: 700,
              color: 'grey'
            }}
          >{". "}
          </div>
          <div style={{flex: 1}} />
          {undoable ? (
          <div
            style={{
              borderRight: '1px solid',
              borderColor: 'grey',
              height: '35px'
            }}
          >
            <div
              style={{
                fontSize: '22px',
                color: highlightColor,
                fontWeight: 700,
                marginLeft: '2px',
                marginRight: '10px',
                cursor: 'pointer'
              }}
              onClick={() => {
                if (typeof(props.state.currentPage.undo) !== 'undefined') {
                  props.updateState(
                    {...props.state, currentPage: props.state.currentPage.undo}
                  )
                }
              }}
            >
              Undo
            </div>
          </div>
          ) : null }
          <div
            style={{
              fontSize: '22px',
              color: highlightColor,
              fontWeight: 700,
              marginLeft: '10px',
              cursor: 'pointer',
              position: 'relative'
            }}
            onClick={() => {
              writeCsv(props.state.currentPage.userFiles)
              if (props.state.example === 'DOWNLOAD_FILES') {
                fireEvent("Example download files")
                AppNotification({kind: 'SUCCESS', message: 'Feel free to try other features as well :)', title: 'Thanks for trying Blocksheet!', time: 5000, callback: () => {}})()
                props.updateState({...props.state, example: undefined})
              }
            }}
          >
            {props.state.example === 'DOWNLOAD_FILES'
              ? ( <div
                  style={{
                    position: 'absolute',
                    top: '-40px',
                    left: '-190px',
                    fontSize: '20px',
                    fontWeight: 700,
                    color: toolToColor('SELECT')
                  }}
                >
                  Download your carrot data!
                </div>
              ) : null}
            Download
          </div>
        </div>
        <span
          style={{
            width: '100%',
            borderBottom: '1px solid',
            borderColor: 'black'
          }}
        />
        {props.state.currentPage.userFiles.map((f,key) => {
          return (
            <div
              key={key}
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%'
                }}
              >
                <input
                  type="text"
                  value={f.name}
                  onChange={(e) => {
                    props.updateState({
                      ...props.state,
                      currentPage: {
                        ...props.state.currentPage,
                        userFiles: [
                          ...props.state.currentPage.userFiles.slice(0,key),
                          {
                            ...props.state.currentPage.userFiles[key],
                            name: e.target.value
                          },
                        ...props.state.currentPage.userFiles.slice(key + 1,props.state.currentPage.userFiles.length)
                        ]
                      }
                    })
                  }}
                  placeholder={""}
                  style={{
                  width: '90%',
                  fontSize: '18px',
                  fontWeight: 700,
                  lineHeight: '38px',
                  border: 0,
                  outlineWidth: 0
                  }}
                >
                </input>
                <div style={{flex: 1}}></div>
                <Link
                  key={key}
                  to="/concat/view"
                  style={{ textDecoration: 'none' }}
                >
                <div
                    onClick={() => {
                      props.updateState({
                        currentPage: {
                          kind: 'TABLE',
                          userFile: f,
                          highlights: [],
                          highlightColor: highlightColor
                        },
                        prevPage: props.state.currentPage,
                        sidemenuOpen: false
                      })
                    }}
                    style={{
                      marginTop: '0.83em',
                      marginBottom: '0.83em',
                      fontSize: '22px',
                      color: highlightColor,
                      fontWeight: 700,
                      cursor: 'pointer'
                    }}>
                      View
                  </div>
                  </Link>
              </div>
              <span
              style={{
                width: '100%',
                borderBottom: '1px solid',
                borderColor: 'grey'
              }}
              />
          </div>
          )
        })}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '80px',
          marginBottom: '80px'
        }}
      >
        <div
          style={{
            width: '600px',
            height: '450px'
          }}
        >
          <MyDropzone
            state={props.state}
            stateChange={(s: State<ToolPage>) => {
              props.updateState({
                ...props.state,
                currentPage: {
                  ...props.state.currentPage,
                  userFiles: [...props.state.currentPage.userFiles, ...s.currentPage.userFiles]
                }
              })
            }}
            textSize={'28px'}
            tool={'CONCAT'}
          />
        </div>
      </div>
    </div>
  )
}