import React from 'react'
import { State, SearchPage, Page, UserFile, ToolPage } from '../../../Types'
import { ContentMaxWidth, toolToColor } from '../../../Constants'
import { Header } from '../../Header'
import { fireEvent } from '../../../libs/mixpanel'
import { searchSA } from '../../../libs/substringArray'
import { groupOccurrences } from '../../../libs/CommonFp'
import { MyDropzone } from '../DataDrop'

import {
  Link,
} from "react-router-dom"

type Props = {
  updateState: (s: State<Page>) => void
  state: State<SearchPage>
}

export const doSearch = (page: SearchPage) => {
  const s = page.searchString
  if (typeof(s) !== 'undefined' && s.length > 0) {
    const searchResultIndex = searchSA(page.SA, s)
    const groupedOccurrences = searchResultIndex > -1
    ? groupOccurrences(page.SA[searchResultIndex].occurrences)
    : undefined
    return groupedOccurrences
  }
  return undefined
}

export const Search = (props: Props) => {
  const highlightColor = toolToColor(props.state.currentPage.kind)
  const groupedOccurrences = doSearch(props.state.currentPage)
  const searchString = props.state.currentPage.searchString
  if (searchString?.toLocaleLowerCase() === 'carrots'
    && props.state.example === 'SEARCH_CARROTS') {
    props.updateState({...props.state, example: 'MOVE_TO_CONCAT'})
    fireEvent('Example carrot written')
  }
  const searchPlaceHolder = props.state.example
    && props.state.example === 'SEARCH_CARROTS'
      ? 'Search for carrots here!'
      : "Type here to search..."
  return (
    <div
      style={{
        width: ContentMaxWidth,
        height: '100%',
      }}
    >
      <Header state={props.state} updateState={props.updateState} />
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '50px',
          position: 'relative'
        }}
      >
        {props.state.example === 'SEARCH_CARROTS'
          ? (<div
              style={{
                position: 'absolute',
                top: '-27px',
                left: '0px',
                fontSize: '20px',
                fontWeight: 700,
                color: toolToColor('SELECT')
              }}
          >
            Search 'carrots' to select all matching files!
          </div>)
          : null
        }
        <input
          type="text"
          value={searchString ? searchString : ''}
          onChange={(e) => {
            props.updateState({
              ...props.state,
              currentPage: {
                ...props.state.currentPage,
                searchString: e.target.value
              }
            })
          }}
          placeholder={searchPlaceHolder}
          style={{
           width: '90%',
           height: '50px',
           fontSize: '28px',
           fontWeight: 700,
           lineHeight: '38px',
           border: 0,
           borderBottom: 'solid',
           borderBottomWidth: '1px',
           outlineWidth: 0
          }}
        >

        </input>
        {
          props.state.currentPage.userFiles.map((file: UserFile, key: number) => {
            const ocs: {file: number, x: number, y: number}[] = groupedOccurrences ? groupedOccurrences[key] : []
            if (groupedOccurrences && (!ocs || ocs.length < 1)) {
              return null
            }
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '90%',
                  borderBottom: '1px solid grey'
                }}
                key={key}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <div
                    style={{
                      fontSize: '18px',
                      display: 'flex',
                      alignItems: 'center',
                      fontWeight: 700,
                      lineHeight: '38px',
                      border: 0,
                      outlineWidth: 0
                    }}
                  >{file.name}</div>
                  <div
                    style={{
                      flex: 1
                    }}
                  ></div>
                  <Link to="/search/view"
                        style={{ textDecoration: 'none '}}
                  >
                    <div
                      onClick={() => {
                        props.updateState({
                          currentPage: {
                            kind: 'TABLE',
                            userFile: file,
                            highlights: ocs,
                            highlightColor: highlightColor
                          },
                          prevPage: props.state.currentPage,
                          sidemenuOpen: false
                        })
                      }}
                      style={{
                        marginTop: '0.83em',
                        marginBottom: '0.83em',
                        fontSize: '22px',
                        color: highlightColor,
                        fontWeight: 700,
                        cursor: 'pointer'
                      }}>
                        View
                    </div>
                  </Link>
                </div>
                { ocs && ocs.length > 0 ?
                  (<table
                  style={{
                    width: '100%'
                  }}
                >
                  <tbody>
                  <tr
                    key={-1}
                  >
                    <th style={{color: 'grey'}}>Column</th>
                    <th style={{color: 'grey'}}>Row</th>
                    <th style={{color: 'grey'}}>Match</th>
                  </tr>
                  {ocs.map((oc, key) => (
                      <tr
                        key={key}
                      >
                        <th
                          style={{
                            width: '5%'
                          }}
                        >
                          {oc.x}
                        </th>
                        <th
                          style={{
                            width: '5%'
                          }}
                        >
                          {oc.y}
                        </th>
                        <th
                          style={{
                            width: '90%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center'
                          }}
                          key={3}
                        >

                          {file.parsedData[oc.x][oc.y]}
                        </th>
                      </tr>
                ))}
                </tbody>
                </table>)
                : null
                }
              </div>
            )
          })
        }
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '80px',
          marginBottom: '80px'
        }}
      >
        <div
          style={{
            width: '600px',
            height: '450px'
          }}
        >
          <MyDropzone
            state={props.state}
            stateChange={(s: State<ToolPage>) => {
              props.updateState({
                ...props.state,
                currentPage: {
                  ...props.state.currentPage,
                  userFiles: [...props.state.currentPage.userFiles, ...s.currentPage.userFiles]
                }
              })
            }}
            textSize={'28px'}
            tool={'SEARCH'}
          />
        </div>
      </div>
    </div>
  )
}