import React from 'react'
import { State, SplitPage, Page, UserFile, ToolPage } from '../../../Types'
import { ContentMaxWidth, toolToColor } from '../../../Constants'
import { Header } from '../../Header'
import { writeCsv } from '../../../libs/Download'
import { splitByRow, splitByColumn } from '../../../libs/CommonFp'
import {
  Link,
} from "react-router-dom"
import { MyDropzone } from '../DataDrop'

const numberToOrdinal = (n: number | undefined): string => {
  if (typeof(n) === 'undefined') {
    return 'th'
  }

  if (n > 9 && n < 20) {
    return "th"
  }

  const lastone = Number(n.toString().split('').pop())
  switch (lastone) {
    case 0: return "th"
    case 1: return "st"
    case 2: return "nd"
    case 3: return "rd"
    default: return "th"
  }
}

type Props = {
  updateState: (s: State<Page>) => void
  state: State<SplitPage>
}
export const Split = (props: Props) => {
  const undoable = typeof(props.state.currentPage.undo) !== 'undefined'
  const highlightColor = toolToColor(props.state.currentPage.kind)
  return (
    <div
      style={{
        width: ContentMaxWidth
      }}
    >
      <Header state={props.state} updateState={props.updateState} />
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '50px'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginBottom: '15px'
          }}
        >
          <div
            style={{
              fontSize: '22px',
              fontWeight: 700,
              color: 'grey',
              marginRight: '10px'
            }}
          >{"Split by every"}
          </div>
          <input
            type="number"
            value={props.state.currentPage.n}
            onChange={(e) => {
              const n = Number(e.target.value)
              props.updateState({
                ...props.state,
                currentPage: {
                  ...props.state.currentPage,
                  n: n > 0 ? n : 0
                }
              })
            }}
            placeholder={""}
            style={{
            color: 'grey',
            width: '75px',
            fontSize: '26px',
            fontWeight: 700,
            lineHeight: '38px',
            border: 0,
            outlineWidth: 0,
            borderStyle: 'solid',
            borderBottomWidth: '1px',
            borderColor: 'grey',
            marginRight: '10px'
            }}
          >
          </input>
          <div
            style={{
              fontSize: '22px',
              fontWeight: 700,
              color: 'grey',
              marginLeft: '5px',
              marginRight: '4px'
            }}
          >{numberToOrdinal(props.state.currentPage.n)}
          </div>
          <div
            style={{
              fontSize: '22px',
              color: highlightColor,
              fontWeight: 700,
              marginLeft: '2px',
              marginRight: '4px',
              cursor: 'pointer'
            }}
            onClick={() => {
              if (props.state.currentPage.n > 0) {
                props.updateState(
                  {
                    ...props.state,
                    currentPage: {
                      ...props.state.currentPage,
                      userFiles: props.state.currentPage.userFiles.map(f =>
                        splitByColumn(f.parsedData, props.state.currentPage.n).map(n => ({
                          ...f,
                          parsedData: n
                        }))).reduce((a,b) => a.concat(b),[]),
                      undo: props.state.currentPage
                    }
                  }
                )
              }
            }}
          >
            {" column "}
          </div>
          <div
          style={{
            fontSize: '22px',
            fontWeight: 700,
            color: 'grey'
          }}
          >
            {"or"}
          </div>
          <div
            style={{
              fontSize: '22px',
              color: highlightColor,
              fontWeight: 700,
              marginLeft: '4px',
              cursor: 'pointer'
            }}
            onClick={() => {
              if (props.state.currentPage.n) {
                props.updateState(
                  {
                    ...props.state,
                    currentPage: {
                      ...props.state.currentPage,
                      userFiles: props.state.currentPage.userFiles.map(f =>
                        splitByRow(f.parsedData, props.state.currentPage.n).map(n => ({
                          ...f,
                          parsedData: n
                        }))).reduce((a,b) => a.concat(b),[]),
                        undo: props.state.currentPage
                    }
                  }
                )
              }
            }}
          >
            {" row"}
          </div>
          <div
            style={{
              fontSize: '22px',
              fontWeight: 700,
              color: 'grey'
            }}
          >{". "}
          </div>
          <div style={{flex: 1}} />
          {undoable ? (
          <div
            style={{
              borderRight: '1px solid',
              borderColor: 'grey',
              height: '35px'
            }}
          >
            <div
              style={{
                fontSize: '22px',
                color: highlightColor,
                fontWeight: 700,
                marginLeft: '2px',
                marginRight: '10px',
                cursor: 'pointer'
              }}
              onClick={() => {
                if (typeof(props.state.currentPage.undo) !== 'undefined') {
                  props.updateState(
                    {...props.state, currentPage: props.state.currentPage.undo}
                  )
                }
              }}
            >
              Undo
            </div>
          </div>
          ) : null }
          <div
            style={{
              fontSize: '22px',
              color: highlightColor,
              fontWeight: 700,
              marginLeft: '10px',
              cursor: 'pointer'
            }}
            onClick={() => {
              writeCsv(props.state.currentPage.userFiles)
            }}
          >
            Download
          </div>
        </div>
        <span
          style={{
            width: '100%',
            borderBottom: '1px solid',
            borderColor: 'black'
          }}
        />
        {props.state.currentPage.userFiles.map((f: UserFile, key: number) => {
          return (
            <div
              key={key}
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%'
                }}
              >
                <input
                  type="text"
                  value={f.name}
                  onChange={(e) => {
                    props.updateState({
                      ...props.state,
                      currentPage: {
                        ...props.state.currentPage,
                        userFiles: [
                          ...props.state.currentPage.userFiles.slice(0,key),
                          {
                            ...props.state.currentPage.userFiles[key],
                            name: e.target.value
                          },
                        ...props.state.currentPage.userFiles.slice(key + 1,props.state.currentPage.userFiles.length)
                        ]
                      }
                    })
                  }}
                  placeholder={""}
                  style={{
                  width: '90%',
                  fontSize: '18px',
                  fontWeight: 700,
                  lineHeight: '38px',
                  border: 0,
                  outlineWidth: 0
                  }}
                >
                </input>
                <div style={{flex: 1}}></div>
                <Link
                  to={"/split/view"}
                  style={{ textDecoration: 'none' }}
                >
                  <div
                      onClick={() => {
                        props.updateState({
                          currentPage: {
                            kind: 'TABLE',
                            userFile: f,
                            highlights: [],
                            highlightColor: highlightColor
                          },
                          prevPage: props.state.currentPage,
                          sidemenuOpen: false
                        })
                      }}
                      style={{
                        marginTop: '0.83em',
                        marginBottom: '0.83em',
                        fontSize: '22px',
                        color: highlightColor,
                        fontWeight: 700,
                        cursor: 'pointer'
                      }}>
                        View
                    </div>
                  </Link>
              </div>
              <span
              style={{
                width: '100%',
                borderBottom: '1px solid',
                borderColor: 'grey'
              }}
              />
          </div>
          )
        })}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '80px',
          marginBottom: '80px'
        }}
      >
        <div
          style={{
            width: '600px',
            height: '450px'
          }}
        >
          <MyDropzone
            state={props.state}
            stateChange={(s: State<ToolPage>) => {
              props.updateState({
                ...props.state,
                currentPage: {
                  ...props.state.currentPage,
                  userFiles: [...props.state.currentPage.userFiles, ...s.currentPage.userFiles]
                }
              })
            }}
            textSize={'28px'}
            tool={'SPLIT'}
          />
        </div>
      </div>
    </div>
  )
}