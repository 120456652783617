import { UserFile } from "src/Types";
import { getHeaders } from './Merge';

export const copyToNewColumn = (
  newHeader: string, 
  userFiles: UserFile[],
  fromHeaders: (string | undefined)[]
  ): UserFile[] => {
  
  return userFiles.map((f,k) => {
    const newSheet = [...f.parsedData]
    const headerRow = f.headers
    newSheet[headerRow.line] = [...newSheet[headerRow.line], newHeader]
    const fromHeader = fromHeaders[k]
    if (typeof(fromHeader) === 'undefined') {
      for (let j = headerRow.line + 1; j < newSheet.length; ++j) {
        newSheet[j] = [...newSheet[j], '']
      }
    } else {
      const fromIndex = headerRow.getColumn[fromHeader]
      for (let j = headerRow.line + 1; j < newSheet.length; ++j) {
        newSheet[j] = [...newSheet[j], newSheet[j][fromIndex]]
      }
    }
    // Fix the empty cells
    const rowLength = newSheet[headerRow.line].length
    for (let j = 0; j < headerRow.line; ++j) {
      for (let i = 0; i < rowLength; ++i) {
        if (typeof(newSheet[j][i]) === 'undefined' || typeof(newSheet[j][i]) === 'object') {
          newSheet[j][i] = ''
        }
      }
    } 
    return ({...f, parsedData: newSheet, headers: getHeaders(newSheet)})
  })
}
