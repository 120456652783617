import {NotificationManager} from 'react-notifications'

type NotificationKind = 'INFO' | 'SUCCESS' | 'WARNING' | 'ERROR'
export const AppNotification = (n: {kind: NotificationKind, message: string, title: string, time: number, callback: () => void}) => {
    const defaultTime = 5000
    return () => {
      switch (n.kind) {
        case 'INFO':
          NotificationManager.info(n.message)
          break
        case 'SUCCESS':
          NotificationManager.success(n.message, n.title)
          break
        case 'WARNING':
          NotificationManager.warning(n.message, n.title, n.time ? n.time : defaultTime)
          break
        case 'ERROR':
          NotificationManager.error(n.message, n.title, n.time ? n.time : defaultTime, n.callback)
          break
      }
    }
  }