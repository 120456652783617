export type Tool = 'SEARCH' | 'CONCAT' | 'SPLIT' | 'TOXLSX' | 'STATS' | 'SELECT' | 'MERGE'  | 'SORT' | 'GROUPBY' | 'COPY' | 'CALC'
export const AllTools: Tool[] = ['SEARCH', 'CONCAT', 'SPLIT', 'TOXLSX', 'STATS', 'SELECT', 'MERGE', 'SORT', 'GROUPBY', 'COPY', 'CALC']

export type CalcPage = {
  kind: 'CALC'
  userFiles: UserFile[]
  undo: CalcPage | undefined
}

export type CopyPage = {
  kind: 'COPY'
  userFiles: UserFile[]
  headerToCopy: (undefined | string)[]
  columnName: string
  undo: CopyPage | undefined
}

export type GroupbyPage = {
  kind: 'GROUPBY'
  userFiles: UserFile[]
  undo: GroupbyPage | undefined
}

export type SortPage = {
  kind: 'SORT'
  userFiles: UserFile[]
  selectedHeader?: string
  sortType: SortType
  undo: SortPage | undefined
}

export type MergePage = {
  kind: 'MERGE'
  userFiles: UserFile[]
  mergedFile?: UserFile
}

export type SelectPage = {
  kind: 'SELECT'
  userFiles: UserFile[]
  headers: { [header: string] : boolean }
  undo: SelectPage | undefined
}

export type StatsPage = {
  kind: 'STATS'
  userFiles: UserFile[]
  header: boolean
}

export type SplitPage = {
  kind: 'SPLIT'
  n: number
  userFiles: UserFile[]
  undo: SplitPage | undefined
}

export type ToXLSXPage = {
  kind: 'TOXLSX'
  workbookName: string
  userFiles: UserFile[]
}

export type SearchPage = {
  kind: 'SEARCH'
  userFiles: UserFile[]
  SA: Substrings[]
  searchString?: string
}

export type TablePage = {
  kind: 'TABLE'
  userFile: UserFile
  highlights: { file: number, x: number, y: number}[]
  highlightColor: string
}

export type ConcatPage = {
  kind: 'CONCAT'
  userFiles: UserFile[]
  undo: ConcatPage | undefined
}

export type LandingPage = {
  kind: 'LANDINGPAGE'
}

export type DataDropPage = {
  kind: 'DATADROP'
  tool: Tool
}

export type Page = LandingPage
  | DataDropPage
  | TablePage
  | SearchPage
  | ConcatPage
  | SplitPage
  | ToXLSXPage
  | StatsPage
  | SelectPage
  | MergePage
  | SortPage
  | GroupbyPage
  | CopyPage
  | CalcPage

export type ToolPage = | SearchPage
  | ConcatPage
  | SplitPage
  | ToXLSXPage
  | StatsPage
  | SelectPage
  | MergePage
  | SortPage
  | GroupbyPage
  | CopyPage
  | CalcPage

export type State<T extends Page> = {
  currentPage: T
  prevPage: Page | undefined
  sidemenuOpen: boolean
  example?: ExampleState
}

export type UserFile = {
  kind: 'CSV'
  name: string
  size: string
  originalData: string
  parsedData: string[][]
  headers: Headers
  stats: ColumnStats[]
}

export type StateValidationSuccess<T> = {
  kind: 'SUCCESS'
  result: T
}
export type StateValidationFailure = {
  kind: 'FAILURE'
  error: string
}
export type StateValidationResult<T> = StateValidationSuccess<T> | StateValidationFailure

export type Substrings = {
  substring: string
  occurrences: {
    file: number
    x: number
    y: number
  }[]
}

export type ColumnStats = {
  header: string | undefined
  median: string
  mode: string
  cardinality: number
  longest: string
  shortest: string
}

export type ExampleState = 'SEARCH_CARROTS'
  | 'MOVE_TO_CONCAT'
  | 'CONCAT_FILES'
  | 'DOWNLOAD_FILES'

export type SortType = 'ALPHABET' | 'NUMBER' | 'DATE'

export type HeaderType = 'STRING' | 'NUMBER'
export type Headers = { line: number, headers: string[], getColumn: {[h: string]: number}, headerTypes: HeaderType[] }
