import * as React from 'react'
import 'react-notifications/lib/notifications.css'
import {NotificationContainer} from 'react-notifications'
import './App.css'
import { fireEvent } from './libs/mixpanel'
import { LandingPage } from './LandingPage/LandingPage'
import { DataDrop } from './LandingPage/DataDrop/DataDrop'
import { Search } from './LandingPage/DataDrop/Search/Search'
import { Concat } from './LandingPage/DataDrop/Concat/Concat'
import { TablePageComponent } from './TablePage/TablePageComponent'
import { Split } from './LandingPage/DataDrop/Split/Split'
import { ToXlsx } from './LandingPage/DataDrop/ToXlsx/ToXlsx'
import { Stats } from './LandingPage/DataDrop/Stats/Stats'
import { Select } from './LandingPage/DataDrop/Select/Select'
import { Groupby } from './LandingPage/DataDrop/Groupby/Groupby'
import { Copy } from './LandingPage/DataDrop/Copy/Copy'
import { Sort } from './LandingPage/DataDrop/Sort/Sort'
import { Merge } from './LandingPage/DataDrop/Merge/Merge'
import { Calc } from './LandingPage/DataDrop/Calc/Calc'
import { Sidemenu } from './Sidemenu/Sidemenu'
import { updateState } from './LandingPage/DataDrop/StateValidations'
import {
  State,
  Page,
  DataDropPage,
  SearchPage,
  SplitPage,
  ConcatPage,
  TablePage,
  ToXLSXPage,
  StatsPage,
  SelectPage,
  MergePage,
  GroupbyPage,
  Tool,
  SortPage,
  CopyPage,
  CalcPage
} from './Types'
import {
  Router,
  Switch,
  Route,
} from "react-router-dom"

import { createBrowserHistory } from 'history'
export const history = createBrowserHistory()

const refresCacheAndReload = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(function(names) {
      for (let name of names) caches.delete(name)
    })
  }
  // delete browser cache and hard reload
  window.location.reload(true)
}

class App extends React.Component<{}, State<Page>> {


  constructor(props: any) {
    super(props)
    this.state = {
      currentPage: { kind: 'LANDINGPAGE' },
      prevPage: undefined,
      sidemenuOpen: false
    }
    this.resolveStateComponent = this.resolveStateComponent.bind(this)
    this.validatePath = this.validatePath.bind(this)
    this.setState = this.setState.bind(this)
    fireEvent("Landing Page")
  }

  componentDidMount() {

    // Listen for changes to the current location.
    // @ts-ignore
    const unlisten = history.listen((location, action) => {
      updateState(this.state, this.setState)(location.pathname)
    })

    fetch("/meta.json", { cache: 'no-cache' })
    .then(res => res.json())
    .then(meta => {
      if (meta && meta.version) {
        if (meta.version !== process.env.REACT_APP_VERSION) {
          refresCacheAndReload()
        }
      }
    })
    .catch(() => {
      // Ignore error on purpose
    })
  }

  resolveStateComponent() {
    switch (this.state.currentPage.kind) {
      case 'LANDINGPAGE': return <LandingPage state={this.state} updateState={this.setState} />
      case 'DATADROP': return <DataDrop state={this.state as State<DataDropPage>} stateChange={this.setState} />
      case 'SEARCH': return <Search updateState={this.setState} state={this.state as State<SearchPage>}/>
      case 'CONCAT': return <Concat updateState={this.setState} state={this.state as State<ConcatPage>}/>
      case 'TABLE': return <TablePageComponent updateState={this.setState} state={this.state as State<TablePage>} />
      case 'SPLIT': return <Split updateState={this.setState} state={this.state as State<SplitPage>} />
      case 'TOXLSX': return <ToXlsx updateState={this.setState} state={this.state as State<ToXLSXPage>} />
      case 'STATS': return <Stats updateState={this.setState} state={this.state as State<StatsPage>} />
      case 'SELECT': return <Select updateState={this.setState} state={this.state as State<SelectPage>} />
      case 'MERGE': return <Merge updateState={this.setState} state={this.state as State<MergePage>} />
      case 'SORT': return <Sort updateState={this.setState} state={this.state as State<SortPage>} />
      case 'GROUPBY': return <Groupby updateState={this.setState} state={this.state as State<GroupbyPage>} />
      case 'COPY': return <Copy updateState={this.setState} state={this.state as State<CopyPage>} />
      case 'CALC': return <Calc updateState={this.setState} state={this.state as State<CalcPage>} />
    }
  }

  validatePath(t: Tool, sidemenuOpen: boolean, table: boolean = false) {
    if (table && this.state.currentPage.kind === 'TABLE') {
      return this.resolveStateComponent()
    }
    if (t === this.state.currentPage.kind || this.state.currentPage.kind === 'TABLE') {
      return this.resolveStateComponent()
    } else {
      return (<DataDrop
        stateChange={this.setState}
        state={
          { currentPage: {kind: 'DATADROP', tool: t }, prevPage: undefined, sidemenuOpen: sidemenuOpen }
        } />)
    }
  }


  public render() {
    return (
      <Router history={history}>
        <div className="app-container" style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Switch>
            <Route path="/search">
              {this.validatePath('SEARCH', this.state.sidemenuOpen)}
            </Route>
            <Route path="/search/view">
              {this.validatePath('SEARCH', this.state.sidemenuOpen, true)}
            </Route>
            <Route path="/concat">
              {this.validatePath('CONCAT', this.state.sidemenuOpen)}
            </Route>
            <Route path="/concat/view">
              {this.validatePath('CONCAT', this.state.sidemenuOpen, true)}
            </Route>
            <Route path="/select">
              {this.validatePath('SELECT', this.state.sidemenuOpen)}
            </Route>
            <Route path="/select/view">
              {this.validatePath('SELECT', this.state.sidemenuOpen, true)}
            </Route>
            <Route path="/merge">
              {this.validatePath('MERGE', this.state.sidemenuOpen)}
            </Route>
            <Route path="/merge/view">
              {this.validatePath('MERGE', this.state.sidemenuOpen, true)}
            </Route>
            <Route path="/sort">
              {this.validatePath('SORT', this.state.sidemenuOpen)}
            </Route>
            <Route path="/sort/view">
              {this.validatePath('SORT', this.state.sidemenuOpen, true)}
            </Route>
            <Route path="/groupby">
              {this.validatePath('GROUPBY', this.state.sidemenuOpen)}
            </Route>
            <Route path="/groupby/view">
              {this.validatePath('GROUPBY', this.state.sidemenuOpen, true)}
            </Route>
            <Route path="/copy">
              {this.validatePath('COPY', this.state.sidemenuOpen)}
            </Route>
            <Route path="/copy/view">
              {this.validatePath('COPY', this.state.sidemenuOpen, true)}
            </Route>
            <Route path="/split">
              {this.validatePath('SPLIT', this.state.sidemenuOpen)}
            </Route>
            <Route path="/split/view">
              {this.validatePath('SPLIT', this.state.sidemenuOpen, true)}
            </Route>
            <Route path="/stats">
              {this.validatePath('STATS', this.state.sidemenuOpen)}
            </Route>
            <Route path="/stats/view">
              {this.validatePath('STATS', this.state.sidemenuOpen, true)}
            </Route>
            <Route path="/toxlsx">
              {this.validatePath('TOXLSX', this.state.sidemenuOpen)}
            </Route>
            <Route path="/toxlsx/view">
              {this.validatePath('TOXLSX', this.state.sidemenuOpen, true)}
            </Route>
            <Route path="/calc">
              {this.validatePath('CALC', this.state.sidemenuOpen)}
            </Route>
            <Route path="/calc/view">
              {this.validatePath('CALC', this.state.sidemenuOpen, true)}
            </Route>
            <Route path="/">
              <LandingPage state={this.state} updateState={this.setState} />
            </Route>
          </Switch>
          <NotificationContainer />
          {this.state.sidemenuOpen ? <Sidemenu state={this.state} updateState={this.setState} /> : null}
        </div>
      </Router>
    )
  }
}

export default App
