import { Substrings } from '../Types'

type Substring = {
  suffix: string
  oc: {
    file: number
    x: number
    y: number
  }
}

const getSubstrings = (s: string): string[] => {
  const substrings: string[] = []
  for (let i = 0; i < s.length; ++i) {
    for (let j = 0 + i; j < s.length; ++j) {
      substrings.push(s.slice(i,j+1).toLowerCase())
    }
  }
  return substrings 
}

const splitToSuffixes = (files: string[][][]) => {
  const suffixes: Substring[] = []
  let i = 0
  files.forEach((f,key) => {
    for (let x = 0; x < f.length; ++x) {
      for (let y = 0; y < f[x].length; ++y) {
        const word = f[x][y]
        const substrings = getSubstrings(word)
        substrings.forEach(s => {
          const record = {
            suffix: s,
            oc: {
              file: key,
              x: x,
              y: y
            }
          }
          suffixes[i] = record
          i += 1
        })
      }
    }

  })
  return suffixes
}

const sort = (suffixes: Substring[]) => {
  return suffixes.sort( (a, b) => a.suffix.localeCompare(b.suffix))
}

const takeUniques = (SA: Substring[]) => {
  const uniques: Substrings[] = []
  let i = 0
  let j = 0
  while (j < SA.length) {
    const suffix = SA[j].suffix
    let ji = j+1
    const occurrences = []
    occurrences[0] = SA[j].oc
    while (ji < SA.length && suffix === SA[ji].suffix) {
      occurrences.push(SA[ji].oc)
      ji += 1
    }
    j = ji
    const record = {
      substring: suffix,
      occurrences: occurrences
    }
    uniques[i] = record
    i += 1
  }
  return uniques
}

export const searchSA = (R: Substrings[], x: string) => {
  let left = 0
  let right = R.length
  const p = x.toLowerCase()
  while ( (right - left) > 0) {
    let mid = Math.floor((left + right) / 2)

    if (R[mid].substring < p) {
      left = mid + 1
    } else if (R[mid].substring > p){
      right = mid - 1
    } else {
      return mid
    }
  }
  if (R[left].substring === p) {
    return left
  } else {
    return -1
  }
}

export const filesToSA = (files: string[][][]): Substrings[] => {
  return takeUniques(sort(splitToSuffixes(files)))
}


