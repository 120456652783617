import { UserFile } from '../Types'
import { fireEvent } from '../libs/mixpanel'
import XLSX from 'xlsx'
import { saveAs } from 'file-saver'

export const writeCsv = (userFiles: UserFile[]) => {
  fireEvent("Download CSV")
  userFiles.forEach(f => {
    let csvContent = ""
    f.parsedData.forEach(r => {
      const row = r.join(";")
      csvContent += row + "\r\n"
    })
    const blob = new Blob([csvContent], {type: "octet/stream"})
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.setAttribute("href", url)
    link.setAttribute("download", f.name)
    document.body.appendChild(link) // Required for FF
    link.click()
    document.body.removeChild(link)
  })
}

// JS-XLSX has limitation for sheet name length
// it can't exceed 31 chars.
// also there can't be duplicate names
const formatSheetName = (name: string, n: number, sheetNames: object): string => {
  const defaultName = `sheet-${n}`
  const newName = name.slice(0, 30)
  if (newName.length < 1) {
    return defaultName
  }
  if (sheetNames[newName]) {
    if (newName.length < 5) {
      return defaultName
    } else {
      const newNameWithN = `${newName}(${n})`
      if (newNameWithN.length < 31) {
        return newNameWithN
      }
      const howMuchToRemove = Math.abs(newNameWithN.length - 31)
      return `${newName.slice(0, newName.length - howMuchToRemove)}(${n})`
    }
  }
  return newName
}

const typeConversions = (userFile: UserFile): (string | number)[][] => {
  const sheet: (string | number)[][] = userFile.parsedData.map(d => [...d])
  if (sheet.length < 1) {
    return sheet
  }
  for (let j = 0; j < sheet[0].length; ++j) {
    if (userFile.headers.headerTypes[j] === 'NUMBER') {
      for (let i = userFile.headers.line +1; i < sheet.length; ++i) {
        sheet[i][j] = sheet[i][j] === '' ? sheet[i][j] : Number(sheet[i][j])
      }
    }
  }

  return sheet
}

export const writeXLSX = (userFiles: UserFile[], workbookName: string) => {
  fireEvent("Download XLSX")
  const wb = XLSX.utils.book_new()
  const sheetNames = {}
  userFiles.forEach((f,k) => {
    const ws = XLSX.utils.aoa_to_sheet(typeConversions(f))
    const sheetName = formatSheetName(f.name, k, sheetNames)
    sheetNames[sheetName] = true
    XLSX.utils.book_append_sheet(wb, ws, sheetName)
  })

  var wopts: {bookType: 'xlsx', bookSST: false, type: 'array'} = { bookType: 'xlsx', bookSST:false, type: 'array' }
  var wbout = XLSX.write(wb, wopts)

  saveAs(new Blob([wbout],{type:"application/octet-stream"}), workbookName)
}