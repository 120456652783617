import { UserFile, StateValidationResult, Tool, State, Page, CopyPage, SearchPage, SortPage, MergePage, ConcatPage, ToXLSXPage, SplitPage, SelectPage, StatsPage, GroupbyPage, CalcPage } from '../../Types'
import { filesToSA } from '../../libs/substringArray'
import { statsToFile } from '../../libs/Stats'
import { getHeaders } from '../../libs/Select'
import { doSearch } from './Search/Search'
import { headerIntersection, getHeaders as mergeGetHeaders } from '../../libs/Merge'

export const lengthValidator = (u: UserFile[]): StateValidationResult<UserFile[]> => {
  if (u.length > 0) {
    return {
      kind: 'SUCCESS',
      result: u
    }
  }
  return {
    kind: 'FAILURE',
    error: 'There has to be at least one file!'
  }
}

export const toolToValidator = (t: Tool) => {
  switch (t) {
    case 'SEARCH': return lengthValidator
    case 'CONCAT': return lengthValidator
    case 'SPLIT': return lengthValidator
    case 'TOXLSX': return lengthValidator
    case 'STATS': return lengthValidator
    case 'SELECT': return lengthValidator
    case 'MERGE': return lengthValidator
    case 'SORT': return lengthValidator
    case 'GROUPBY': return lengthValidator
    case 'COPY': return lengthValidator
    case 'CALC': return lengthValidator
  }
}

export const toolToState = (t: Tool, currPage: Page, u: UserFile[]): State<Page> => {
  switch (t) {
    case 'SEARCH': return ({
      currentPage: { kind: 'SEARCH', userFiles: u, SA: filesToSA(u.map(f => f.parsedData))},
      prevPage: currPage,
      sidemenuOpen: false
    })
    case 'CONCAT': return ({
      currentPage: { kind: 'CONCAT', userFiles: u, undo: undefined},
      prevPage: currPage,
      sidemenuOpen: false
    })
    case 'SPLIT': return ({
      currentPage: { kind: 'SPLIT', userFiles: u, n: 0, undo: undefined },
      prevPage: currPage,
      sidemenuOpen: false
    })
    case 'TOXLSX': return ({
      currentPage: { kind: 'TOXLSX', userFiles: u, workbookName: 'Workbook.xlsx' },
      prevPage: currPage,
      sidemenuOpen: false
    })
    case 'STATS': return ({
      currentPage: { kind: 'STATS', userFiles: u.map(statsToFile(true)), header: true },
      prevPage: currPage,
      sidemenuOpen: false
    })
    case 'SELECT': return ({
      currentPage: { kind: 'SELECT', userFiles: u, headers: getHeaders(u), undo: undefined},
      prevPage: currPage,
      sidemenuOpen: false
    })
    case 'MERGE': return ({
      currentPage: { kind: 'MERGE', userFiles: u },
      prevPage: currPage,
      sidemenuOpen: false
    })
    case 'SORT': return ({
      currentPage: { kind: 'SORT', userFiles: u, sortType: 'ALPHABET', undo: undefined },
      prevPage: currPage,
      sidemenuOpen: false
    })
    case 'GROUPBY': return ({
      currentPage: { kind: 'GROUPBY', userFiles: u, undo: undefined },
      prevPage: currPage,
      sidemenuOpen: false
    })
    case 'COPY': return ({
      currentPage: { kind: 'COPY', userFiles: u, headerToCopy: u.map(f => undefined), columnName: 'New Column', undo: undefined},
      prevPage: currPage,
      sidemenuOpen: false
    })
    case 'CALC': return ({
      currentPage: { kind: 'CALC', userFiles: u, undo: undefined},
      prevPage: currPage,
      sidemenuOpen: false
    })
  }
}

// TODO: Refactor these so that the switch cases return STATE!

const resolveConcatState = (state: State<Page>, updateState: (s: State<Page>) => void) => {
  const concatState: (u: UserFile[]) => State<ConcatPage> = (u: UserFile[]) => ({
    ...state,
    currentPage: { kind: 'CONCAT', userFiles: u, undo: undefined },
    prevPage: state.currentPage,
    sidemenuOpen: false
  })
  switch (state.currentPage.kind) {
    case 'CONCAT': updateState({...state, sidemenuOpen: false}); break
    case 'TABLE': {
      const currentPage: Page = state.prevPage ? state.prevPage : { kind: 'LANDINGPAGE' }
      updateState({currentPage: currentPage, sidemenuOpen: false, prevPage: state.currentPage})
      break
    }
    case 'DATADROP': {
      updateState({
        ...state,
        currentPage: { kind: 'DATADROP', tool: 'CONCAT' },
        prevPage: state.currentPage,
        sidemenuOpen: false
      })
      break
    }
    case 'LANDINGPAGE': {
      updateState({
        ...state,
        currentPage: { kind: 'DATADROP', tool: 'CONCAT' },
        prevPage: state.currentPage,
        sidemenuOpen: false
      })
      break
    }
    case 'SEARCH': {
      const groupedOccurrences = doSearch(state.currentPage)
      const userFiles = state.currentPage.userFiles
      const uf = groupedOccurrences
        ? Object.keys(groupedOccurrences)
            .map(k => userFiles[k])
            .filter(f => f)
        : state.currentPage.userFiles
      updateState(concatState(uf))
      break
    }
    case 'SELECT': {
      // change state to concat
      updateState(concatState(state.currentPage.userFiles))
      break
    }
    case 'SPLIT': {
      // change state to concat
      updateState(concatState(state.currentPage.userFiles))
      break
    }
    case 'STATS': {
      // change state to concat
      updateState(concatState(state.currentPage.userFiles))
      break
    }
    case 'TOXLSX': {
      // change state to concat
      updateState(concatState(state.currentPage.userFiles))
      break
    }
    case 'SORT': {
      // change state to concat
      updateState(concatState(state.currentPage.userFiles))
      break
    }
    case 'GROUPBY': {
      // change state to concat
      updateState(concatState(state.currentPage.userFiles))
      break
    }
    case 'COPY': {
      updateState(concatState(state.currentPage.userFiles))
      break
    }
    case 'CALC': {
      updateState(concatState(state.currentPage.userFiles))
      break
    }
    case 'MERGE': {
      updateState(concatState(
        state.currentPage.mergedFile
          ? [state.currentPage.mergedFile]
          : state.currentPage.userFiles
      ))
      break
    }
  }
}
const resolveSearchState = (state: State<Page>, updateState: (s: State<Page>) => void) => {
  const searchState: (u: UserFile[]) => State<SearchPage> = (u: UserFile[]) => ({
    ...state,
    currentPage: {
      kind: 'SEARCH',
      userFiles: u,
      SA: filesToSA(u.map(f => f.parsedData))
    },
    prevPage: state.currentPage,
    sidemenuOpen: false
  })
  switch (state.currentPage.kind) {
    case 'SEARCH': updateState({...state, sidemenuOpen: false}); break
    case 'TABLE': {
      const currentPage: Page = state.prevPage ? state.prevPage : { kind: 'LANDINGPAGE' }
      updateState({currentPage: currentPage, sidemenuOpen: false, prevPage: state.currentPage})
      break
    }
    case 'DATADROP': {
      updateState({
        ...state,
        currentPage: { kind: 'DATADROP', tool: 'SEARCH' },
        prevPage: state.currentPage,
        sidemenuOpen: false
      })
      break
    }
    case 'LANDINGPAGE': {
      updateState({
        ...state,
        currentPage: { kind: 'DATADROP', tool: 'SEARCH' },
        prevPage: state.currentPage,
        sidemenuOpen: false
      })
      break
    }
    case 'CONCAT': {
      updateState(searchState(state.currentPage.userFiles))
      break
    }
    case 'SELECT': {
      // change state to concat
      updateState(searchState(state.currentPage.userFiles))
      break
    }
    case 'SPLIT': {
      // change state to concat
      updateState(searchState(state.currentPage.userFiles))
      break
    }
    case 'STATS': {
      // change state to concat
      updateState(searchState(state.currentPage.userFiles))
      break
    }
    case 'TOXLSX': {
      // change state to concat
      updateState(searchState(state.currentPage.userFiles))
      break
    }
    case 'SORT': {
      // change state to concat
      updateState(searchState(state.currentPage.userFiles))
      break
    }
    case 'GROUPBY': {
      // change state to concat
      updateState(searchState(state.currentPage.userFiles))
      break
    }
    case 'COPY': {
      updateState(searchState(state.currentPage.userFiles))
      break
    }
    case 'CALC': {
      updateState(searchState(state.currentPage.userFiles))
      break
    }
    case 'MERGE': {
      updateState(searchState(
        state.currentPage.mergedFile
          ? [state.currentPage.mergedFile]
          : state.currentPage.userFiles
      ))
      break
    }
  }
}

const resolveToXLSXState = (state: State<Page>, updateState: (s: State<Page>) => void) => {
  const xlsxState: (u: UserFile[]) => State<ToXLSXPage> = (u: UserFile[]) => ({
    ...state,
    currentPage: {
      kind: 'TOXLSX',
      userFiles: u,
      workbookName: 'workbook.xlsx'
    },
    prevPage: state.currentPage,
    sidemenuOpen: false
  })
  switch (state.currentPage.kind) {
    case 'TOXLSX': updateState({...state, sidemenuOpen: false}); break
    case 'TABLE': {
      const currentPage: Page = state.prevPage ? state.prevPage : { kind: 'LANDINGPAGE' }
      updateState({currentPage: currentPage, sidemenuOpen: false, prevPage: state.currentPage})
      break
    }
    case 'DATADROP': {
      updateState({
        ...state,
        currentPage: { kind: 'DATADROP', tool: 'TOXLSX' },
        prevPage: state.currentPage,
        sidemenuOpen: false
      })
      break
    }
    case 'LANDINGPAGE': {
      updateState({
        ...state,
        currentPage: { kind: 'DATADROP', tool: 'TOXLSX' },
        prevPage: state.currentPage,
        sidemenuOpen: false
      })
      break
    }
    case 'CONCAT': {
      updateState(xlsxState(state.currentPage.userFiles))
      break
    }
    case 'SELECT': {
      // change state to concat
      updateState(xlsxState(state.currentPage.userFiles))
      break
    }
    case 'SPLIT': {
      // change state to concat
      updateState(xlsxState(state.currentPage.userFiles))
      break
    }
    case 'STATS': {
      // change state to concat
      updateState(xlsxState(state.currentPage.userFiles))
      break
    }
    case 'SORT': {
      // change state to concat
      updateState(xlsxState(state.currentPage.userFiles))
      break
    }
    case 'GROUPBY': {
      // change state to concat
      updateState(xlsxState(state.currentPage.userFiles))
      break
    }
    case 'COPY': {
      updateState(xlsxState(state.currentPage.userFiles))
      break
    }
    case 'CALC': {
      updateState(xlsxState(state.currentPage.userFiles))
      break
    }
    case 'MERGE': {
      updateState(xlsxState(
        state.currentPage.mergedFile
          ? [state.currentPage.mergedFile]
          : state.currentPage.userFiles
      ))
      break
    }
    case 'SEARCH': {
      const groupedOccurrences = doSearch(state.currentPage)
      const userFiles = state.currentPage.userFiles
      const uf = groupedOccurrences
        ? Object.keys(groupedOccurrences)
            .map(k => userFiles[k])
            .filter(f => f)
        : state.currentPage.userFiles
      updateState(xlsxState(uf))
      break
    }
  }
}

const resolveSplitState = (state: State<Page>, updateState: (s: State<Page>) => void) => {
  const splitState: (u: UserFile[]) => State<SplitPage> = (u: UserFile[]) => ({
    ...state,
    currentPage: {
      kind: 'SPLIT',
      userFiles: u,
      n: 0,
      undo: undefined
    },
    prevPage: state.currentPage,
    sidemenuOpen: false
  })
  switch (state.currentPage.kind) {
    case 'SPLIT': updateState({...state, sidemenuOpen: false}); break
    case 'TABLE': {
      const currentPage: Page = state.prevPage ? state.prevPage : { kind: 'LANDINGPAGE' }
      updateState({currentPage: currentPage, sidemenuOpen: false, prevPage: state.currentPage})
      break
    }
    case 'DATADROP': {
      updateState({
        ...state,
        currentPage: { kind: 'DATADROP', tool: 'SPLIT' },
        prevPage: state.currentPage,
        sidemenuOpen: false
      })
      break
    }
    case 'LANDINGPAGE': {
      updateState({
        ...state,
        currentPage: { kind: 'DATADROP', tool: 'SPLIT' },
        prevPage: state.currentPage,
        sidemenuOpen: false
      })
      break
    }
    case 'CONCAT': {
      updateState(splitState(state.currentPage.userFiles))
      break
    }
    case 'SELECT': {
      // change state to concat
      updateState(splitState(state.currentPage.userFiles))
      break
    }
    case 'TOXLSX': {
      // change state to concat
      updateState(splitState(state.currentPage.userFiles))
      break
    }
    case 'STATS': {
      // change state to concat
      updateState(splitState(state.currentPage.userFiles))
      break
    }
    case 'SORT': {
      // change state to concat
      updateState(splitState(state.currentPage.userFiles))
      break
    }
    case 'GROUPBY': {
      // change state to concat
      updateState(splitState(state.currentPage.userFiles))
      break
    }
    case 'COPY': {
      updateState(splitState(state.currentPage.userFiles))
      break
    }
    case 'CALC': {
      updateState(splitState(state.currentPage.userFiles))
      break
    }
    case 'MERGE': {
      updateState(splitState(
        state.currentPage.mergedFile
          ? [state.currentPage.mergedFile]
          : state.currentPage.userFiles
      ))
      break
    }
    case 'SEARCH': {
      const groupedOccurrences = doSearch(state.currentPage)
      const userFiles = state.currentPage.userFiles
      const uf = groupedOccurrences
        ? Object.keys(groupedOccurrences)
            .map(k => userFiles[k])
            .filter(f => f)
        : state.currentPage.userFiles
      updateState(splitState(uf))
      break
    }
  }
}

const resolveSelectState = (state: State<Page>, updateState: (s: State<Page>) => void) => {
  const selectState: (u: UserFile[]) => State<SelectPage> = (u: UserFile[]) => ({
    ...state,
    currentPage: {
      kind: 'SELECT',
      userFiles: u,
      headers: getHeaders(u),
      undo: undefined
    },
    prevPage: state.currentPage,
    sidemenuOpen: false
  })
  switch (state.currentPage.kind) {
    case 'SELECT': updateState({...state, sidemenuOpen: false}); break
    case 'TABLE': {
      const currentPage: Page = state.prevPage ? state.prevPage : { kind: 'LANDINGPAGE' }
      updateState({currentPage: currentPage, sidemenuOpen: false, prevPage: state.currentPage})
      break
    }
    case 'DATADROP': {
      updateState({
        ...state,
        currentPage: { kind: 'DATADROP', tool: 'SELECT' },
        prevPage: state.currentPage,
        sidemenuOpen: false
      })
      break
    }
    case 'LANDINGPAGE': {
      updateState({
        ...state,
        currentPage: { kind: 'DATADROP', tool: 'SELECT' },
        prevPage: state.currentPage,
        sidemenuOpen: false
      })
      break
    }
    case 'CONCAT': {
      updateState(selectState(state.currentPage.userFiles))
      break
    }
    case 'SPLIT': {
      // change state to concat
      updateState(selectState(state.currentPage.userFiles))
      break
    }
    case 'TOXLSX': {
      // change state to concat
      updateState(selectState(state.currentPage.userFiles))
      break
    }
    case 'STATS': {
      // change state to concat
      updateState(selectState(state.currentPage.userFiles))
      break
    }
    case 'SORT': {
      // change state to concat
      updateState(selectState(state.currentPage.userFiles))
      break
    }
    case 'GROUPBY': {
      // change state to concat
      updateState(selectState(state.currentPage.userFiles))
      break
    }
    case 'COPY': {
      updateState(selectState(state.currentPage.userFiles))
      break
    }
    case 'CALC': {
      updateState(selectState(state.currentPage.userFiles))
      break
    }
    case 'MERGE': {
      updateState(
        selectState(state.currentPage.mergedFile
          ? [state.currentPage.mergedFile]
          : state.currentPage.userFiles
        ))
      break
    }
    case 'SEARCH': {
      const groupedOccurrences = doSearch(state.currentPage)
      const userFiles = state.currentPage.userFiles
      const uf = groupedOccurrences
        ? Object.keys(groupedOccurrences)
            .map(k => userFiles[k])
            .filter(f => f)
        : state.currentPage.userFiles
      updateState(selectState(uf))
      break
    }
  }
}

const resolveStatsState = (state: State<Page>, updateState: (s: State<Page>) => void) => {
  const statsState: (u: UserFile[]) => State<StatsPage> = (u: UserFile[]) => ({
    ...state,
    currentPage: {
      kind: 'STATS',
      userFiles: u,
      header: true
    },
    prevPage: state.currentPage,
    sidemenuOpen: false
  })
  switch (state.currentPage.kind) {
    case 'STATS': updateState({...state, sidemenuOpen: false}); break
    case 'TABLE': {
      const currentPage: Page = state.prevPage ? state.prevPage : { kind: 'LANDINGPAGE' }
      updateState({currentPage: currentPage, sidemenuOpen: false, prevPage: state.currentPage})
      break
    }
    case 'DATADROP': {
      updateState({
        ...state,
        currentPage: { kind: 'DATADROP', tool: 'STATS' },
        prevPage: state.currentPage,
        sidemenuOpen: false
      })
      break
    }
    case 'LANDINGPAGE': {
      updateState({
        ...state,
        currentPage: { kind: 'DATADROP', tool: 'STATS' },
        prevPage: state.currentPage,
        sidemenuOpen: false
      })
      break
    }
    case 'CONCAT': {
      updateState(statsState(state.currentPage.userFiles))
      break
    }
    case 'SPLIT': {
      // change state to concat
      updateState(statsState(state.currentPage.userFiles))
      break
    }
    case 'TOXLSX': {
      // change state to concat
      updateState(statsState(state.currentPage.userFiles))
      break
    }
    case 'SELECT': {
      // change state to concat
      updateState(statsState(state.currentPage.userFiles))
      break
    }
    case 'SORT': {
      // change state to concat
      updateState(statsState(state.currentPage.userFiles))
      break
    }
    case 'GROUPBY': {
      // change state to concat
      updateState(statsState(state.currentPage.userFiles))
      break
    }
    case 'COPY': {
      updateState(statsState(state.currentPage.userFiles))
      break
    }
    case 'CALC': {
      updateState(statsState(state.currentPage.userFiles))
      break
    }
    case 'MERGE': {
      updateState(statsState(
        state.currentPage.mergedFile
          ? [state.currentPage.mergedFile]
          : state.currentPage.userFiles
      ))
      break
    }
    case 'SEARCH': {
      const groupedOccurrences = doSearch(state.currentPage)
      const userFiles = state.currentPage.userFiles
      const uf = groupedOccurrences
        ? Object.keys(groupedOccurrences)
            .map(k => userFiles[k])
            .filter(f => f)
        : state.currentPage.userFiles
      updateState(statsState(uf))
      break
    }
  }
}

const resolveMergeState = (state: State<Page>, updateState: (s: State<Page>) => void) => {
  const mergeState: (u: UserFile[]) => State<MergePage> = (u: UserFile[]) => ({
    ...state,
    currentPage: {
      kind: 'MERGE',
      userFiles: u,
    },
    prevPage: state.currentPage,
    sidemenuOpen: false
  })
  switch (state.currentPage.kind) {
    case 'MERGE': updateState({...state, sidemenuOpen: false}); break
    case 'TABLE': {
      const currentPage: Page = state.prevPage ? state.prevPage : { kind: 'LANDINGPAGE' }
      updateState({currentPage: currentPage, sidemenuOpen: false, prevPage: state.currentPage})
      break
    }
    case 'DATADROP': {
      updateState({
        ...state,
        currentPage: { kind: 'DATADROP', tool: 'MERGE' },
        prevPage: state.currentPage,
        sidemenuOpen: false
      })
      break
    }
    case 'LANDINGPAGE': {
      updateState({
        ...state,
        currentPage: { kind: 'DATADROP', tool: 'MERGE' },
        prevPage: state.currentPage,
        sidemenuOpen: false
      })
      break
    }
    case 'CONCAT': {
      updateState(mergeState(state.currentPage.userFiles))
      break
    }
    case 'SPLIT': {
      updateState(mergeState(state.currentPage.userFiles))
      break
    }
    case 'TOXLSX': {
      updateState(mergeState(state.currentPage.userFiles))
      break
    }
    case 'SELECT': {
      updateState(mergeState(state.currentPage.userFiles))
      break
    }
    case 'SORT': {
      updateState(mergeState(state.currentPage.userFiles))
      break
    }
    case 'GROUPBY': {
      updateState(mergeState(state.currentPage.userFiles))
      break
    }
    case 'COPY': {
      updateState(mergeState(state.currentPage.userFiles))
      break
    }
    case 'CALC': {
      updateState(mergeState(state.currentPage.userFiles))
      break
    }
    case 'SEARCH': {
      const groupedOccurrences = doSearch(state.currentPage)
      const userFiles = state.currentPage.userFiles
      const uf = groupedOccurrences
        ? Object.keys(groupedOccurrences)
            .map(k => userFiles[k])
            .filter(f => f)
        : state.currentPage.userFiles
      updateState(mergeState(uf))
      break
    }
  }
}

const resolveSortState = (state: State<Page>, updateState: (s: State<Page>) => void) => {
  const sortState: (u: UserFile[]) => State<SortPage> = (u: UserFile[]) => ({
    ...state,
    currentPage: {
      kind: 'SORT',
      userFiles: u,
      sortType: 'ALPHABET',
      undo: undefined,
      selectedHeader: undefined,
      headers: headerIntersection(u)
    },
    prevPage: state.currentPage,
    sidemenuOpen: false
  })
  switch (state.currentPage.kind) {
    case 'SORT': updateState({...state, sidemenuOpen: false}); break
    case 'TABLE': {
      const currentPage: Page = state.prevPage ? state.prevPage : { kind: 'LANDINGPAGE' }
      updateState({currentPage: currentPage, sidemenuOpen: false, prevPage: state.currentPage})
      break
    }
    case 'DATADROP': {
      updateState({
        ...state,
        currentPage: { kind: 'DATADROP', tool: 'SORT' },
        prevPage: state.currentPage,
        sidemenuOpen: false
      })
      break
    }
    case 'LANDINGPAGE': {
      updateState({
        ...state,
        currentPage: { kind: 'DATADROP', tool: 'SORT' },
        prevPage: state.currentPage,
        sidemenuOpen: false
      })
      break
    }
    case 'CONCAT': {
      updateState(sortState(state.currentPage.userFiles))
      break
    }
    case 'SPLIT': {
      updateState(sortState(state.currentPage.userFiles))
      break
    }
    case 'TOXLSX': {
      updateState(sortState(state.currentPage.userFiles))
      break
    }
    case 'SELECT': {
      updateState(sortState(state.currentPage.userFiles))
      break
    }
    case 'GROUPBY': {
      updateState(sortState(state.currentPage.userFiles))
      break
    }
    case 'COPY': {
      updateState(sortState(state.currentPage.userFiles))
      break
    }
    case 'CALC': {
      updateState(sortState(state.currentPage.userFiles))
      break
    }
    case 'MERGE': {
      updateState(sortState(
        state.currentPage.mergedFile
          ? [state.currentPage.mergedFile]
          : state.currentPage.userFiles
      ))
      break
    }
    case 'SEARCH': {
      const groupedOccurrences = doSearch(state.currentPage)
      const userFiles = state.currentPage.userFiles
      const uf = groupedOccurrences
        ? Object.keys(groupedOccurrences)
            .map(k => userFiles[k])
            .filter(f => f)
        : state.currentPage.userFiles
      updateState(sortState(uf))
      break
    }
  }
}

const resolveGroupbyState = (state: State<Page>, updateState: (s: State<Page>) => void) => {
  const groupbyState: (u: UserFile[]) => State<GroupbyPage> = (u: UserFile[]) => ({
    ...state,
    currentPage: {
      kind: 'GROUPBY',
      userFiles: u,
      undo: undefined,
      headers: headerIntersection(u)
    },
    prevPage: state.currentPage,
    sidemenuOpen: false
  })
  switch (state.currentPage.kind) {
    case 'GROUPBY': updateState({...state, sidemenuOpen: false}); break
    case 'TABLE': {
      const currentPage: Page = state.prevPage ? state.prevPage : { kind: 'LANDINGPAGE' }
      updateState({currentPage: currentPage, sidemenuOpen: false, prevPage: state.currentPage})
      break
    }
    case 'DATADROP': {
      updateState({
        ...state,
        currentPage: { kind: 'DATADROP', tool: 'GROUPBY' },
        prevPage: state.currentPage,
        sidemenuOpen: false
      })
      break
    }
    case 'LANDINGPAGE': {
      updateState({
        ...state,
        currentPage: { kind: 'DATADROP', tool: 'GROUPBY' },
        prevPage: state.currentPage,
        sidemenuOpen: false
      })
      break
    }
    case 'CONCAT': {
      updateState(groupbyState(state.currentPage.userFiles))
      break
    }
    case 'SPLIT': {
      updateState(groupbyState(state.currentPage.userFiles))
      break
    }
    case 'TOXLSX': {
      updateState(groupbyState(state.currentPage.userFiles))
      break
    }
    case 'SELECT': {
      updateState(groupbyState(state.currentPage.userFiles))
      break
    }
    case 'SORT': {
      updateState(groupbyState(state.currentPage.userFiles))
      break
    }
    case 'COPY': {
      updateState(groupbyState(state.currentPage.userFiles))
      break
    }
    case 'CALC': {
      updateState(groupbyState(state.currentPage.userFiles))
      break
    }
    case 'MERGE': {
      updateState(groupbyState(
        state.currentPage.mergedFile
          ? [state.currentPage.mergedFile]
          : state.currentPage.userFiles
      ))
      break
    }
    case 'SEARCH': {
      const groupedOccurrences = doSearch(state.currentPage)
      const userFiles = state.currentPage.userFiles
      const uf = groupedOccurrences
        ? Object.keys(groupedOccurrences)
            .map(k => userFiles[k])
            .filter(f => f)
        : state.currentPage.userFiles
      updateState(groupbyState(uf))
      break
    }
  }
}

const resolveCopyState = (state: State<Page>, updateState: (s: State<Page>) => void) => {
  const copyState: (u: UserFile[]) => State<CopyPage> = (u: UserFile[]) => ({
    ...state,
    currentPage: {
      kind: 'COPY',
      userFiles: u,
      headersByFile: u.map(f => mergeGetHeaders(f.parsedData)),
      headerToCopy: u.map(f => undefined),
      columnName: 'New Column',
      undo: undefined,
    },
    prevPage: state.currentPage,
    sidemenuOpen: false
  })
  switch (state.currentPage.kind) {
    case 'COPY': updateState({...state, sidemenuOpen: false}); break
    case 'TABLE': {
      const currentPage: Page = state.prevPage ? state.prevPage : { kind: 'LANDINGPAGE' }
      updateState({currentPage: currentPage, sidemenuOpen: false, prevPage: state.currentPage})
      break
    }
    case 'DATADROP': {
      updateState({
        ...state,
        currentPage: { kind: 'DATADROP', tool: 'COPY' },
        prevPage: state.currentPage,
        sidemenuOpen: false
      })
      break
    }
    case 'LANDINGPAGE': {
      updateState({
        ...state,
        currentPage: { kind: 'DATADROP', tool: 'COPY' },
        prevPage: state.currentPage,
        sidemenuOpen: false
      })
      break
    }
    case 'CONCAT': {
      updateState(copyState(state.currentPage.userFiles))
      break
    }
    case 'SPLIT': {
      updateState(copyState(state.currentPage.userFiles))
      break
    }
    case 'TOXLSX': {
      updateState(copyState(state.currentPage.userFiles))
      break
    }
    case 'SELECT': {
      updateState(copyState(state.currentPage.userFiles))
      break
    }
    case 'SORT': {
      updateState(copyState(state.currentPage.userFiles))
      break
    }
    case 'MERGE': {
      updateState(copyState(
        state.currentPage.mergedFile
          ? [state.currentPage.mergedFile]
          : state.currentPage.userFiles
      ))
      break
    }
    case 'SEARCH': {
      const groupedOccurrences = doSearch(state.currentPage)
      const userFiles = state.currentPage.userFiles
      const uf = groupedOccurrences
        ? Object.keys(groupedOccurrences)
            .map(k => userFiles[k])
            .filter(f => f)
        : state.currentPage.userFiles
      updateState(copyState(uf))
      break
    }
    case 'GROUPBY': {
      updateState(copyState(state.currentPage.userFiles))
      break
    }
    case 'CALC': {
      updateState(copyState(state.currentPage.userFiles))
      break
    }
  }
}

const resolveCalcState = (state: State<Page>, updateState: (s: State<Page>) => void) => {
  const calcState: (u: UserFile[]) => State<CalcPage> = (u: UserFile[]) => ({
    ...state,
    currentPage: {
      kind: 'CALC',
      userFiles: u,
      headersByFile: u.map(f => mergeGetHeaders(f.parsedData)),
      undo: undefined,
    },
    prevPage: state.currentPage,
    sidemenuOpen: false
  })
  switch (state.currentPage.kind) {
    case 'CALC': updateState({...state, sidemenuOpen: false}); break
    case 'TABLE': {
      const currentPage: Page = state.prevPage ? state.prevPage : { kind: 'LANDINGPAGE' }
      updateState({currentPage: currentPage, sidemenuOpen: false, prevPage: state.currentPage})
      break
    }
    case 'DATADROP': {
      updateState({
        ...state,
        currentPage: { kind: 'DATADROP', tool: 'CALC' },
        prevPage: state.currentPage,
        sidemenuOpen: false
      })
      break
    }
    case 'LANDINGPAGE': {
      updateState({
        ...state,
        currentPage: { kind: 'DATADROP', tool: 'CALC' },
        prevPage: state.currentPage,
        sidemenuOpen: false
      })
      break
    }
    case 'CONCAT': {
      updateState(calcState(state.currentPage.userFiles))
      break
    }
    case 'SPLIT': {
      updateState(calcState(state.currentPage.userFiles))
      break
    }
    case 'TOXLSX': {
      updateState(calcState(state.currentPage.userFiles))
      break
    }
    case 'SELECT': {
      updateState(calcState(state.currentPage.userFiles))
      break
    }
    case 'SORT': {
      updateState(calcState(state.currentPage.userFiles))
      break
    }
    case 'MERGE': {
      updateState(calcState(
        state.currentPage.mergedFile
          ? [state.currentPage.mergedFile]
          : state.currentPage.userFiles
      ))
      break
    }
    case 'SEARCH': {
      const groupedOccurrences = doSearch(state.currentPage)
      const userFiles = state.currentPage.userFiles
      const uf = groupedOccurrences
        ? Object.keys(groupedOccurrences)
            .map(k => userFiles[k])
            .filter(f => f)
        : state.currentPage.userFiles
      updateState(calcState(uf))
      break
    }
    case 'GROUPBY': {
      updateState(calcState(state.currentPage.userFiles))
      break
    }
    case 'COPY': {
      updateState(calcState(state.currentPage.userFiles))
      break
    }
  }
}

export const updateState = (state: State<Page>, updateState: (s: State<Page>) => void) => (path: string) => {
  const x = path.split('/')
  if (x.length > 1 && x[2] !== 'view') {
    switch (x[1]) {
      case '': {
        if (state.currentPage.kind !== 'LANDINGPAGE') {
          updateState({...state, currentPage: { kind: 'LANDINGPAGE' }, prevPage: state.currentPage, sidemenuOpen: false})
        }
        break
      }
      case 'concat': {
        resolveConcatState(state, updateState)
        break
      }
      case 'search': {
        resolveSearchState(state, updateState)
        break
      }
      case 'toxlsx': {
        resolveToXLSXState(state, updateState)
        break
      }
      case 'split': {
        resolveSplitState(state, updateState)
        break
      }
      case 'select': {
        resolveSelectState(state, updateState)
        break
      }
      case 'stats': {
        resolveStatsState(state, updateState)
        break
      }
      case 'merge': {
        resolveMergeState(state, updateState)
        break
      }
      case 'sort': {
        resolveSortState(state, updateState)
        break
      }
      case 'groupby': {
        resolveGroupbyState(state, updateState)
        break
      }
      case 'copy': {
        resolveCopyState(state, updateState)
        break
      }
      case 'calc': {
        resolveCalcState(state, updateState)
        break
      }
      default: {
        updateState({...state, currentPage: { kind: 'LANDINGPAGE' }, prevPage: state.currentPage, sidemenuOpen: false})
        break
      }
    }
  }
}