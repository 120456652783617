
import { UserFile, SortType } from '../Types'

type Headers = { line: number, headers: string[], getColumn: {[h: string]: number} }
const getHeaders = (sheet: string[][]): Headers => {
  const lines: number[] = []
  if (sheet.length > 0) {
    for (let i = 0; i < sheet[0].length; ++i) {
      for (let j = 0; j < sheet.length; ++j) {
        const v = sheet[j][i]
        if (v !== '' && v !== undefined) {
          lines.push(j)
          break
        }
      }
    }
  }

  let mostCommon = -1
  let n = 0
  let current = -1
  let cn = 0
  lines.sort().forEach(s => {
    if (s !== current) {
      current = s
      cn = 1
    } else {
      cn += 1
    }
    if (cn > n) {
      n = cn
      mostCommon = current
    }
  })

  const result: Headers = {
    line: mostCommon,
    headers: [],
    getColumn: {}
  }
  for (let i = 0; i < sheet[mostCommon].length; ++i) {
    result.headers[i] = sheet[mostCommon][i]
    result.getColumn[sheet[mostCommon][i]] = i
  }
  return result
}

export const sort = (uf: UserFile, header: string, sortType: SortType): UserFile => {
  const headers = getHeaders(uf.parsedData)
  if (uf.parsedData.length < headers.line) {
    return uf
  }

  let columnIndex = -1
  for (let i = 0; i < uf.parsedData[headers.line].length; ++i) {
    if (uf.parsedData[headers.line][i] === header) {
      columnIndex = i
    }
  }
  // Check that the column exist in the sheet
  if (columnIndex === -1) {
    return uf
  }

  const rowsToSort = uf.parsedData.slice(headers.line+1, uf.parsedData.length)
  const sorting = (a: string, b: string): number => {
    const isNaN = (maybeNaN: number) => maybeNaN !== maybeNaN

    switch(sortType) {
      case 'ALPHABET': {
        if (a === '') {
          return 1
        }
        if (b === '') {
          return -1
        }
        if (a < b) {
          return -1
        } else if (a > b) {
          return 1
        } else {
          return 0
        }
      }
      case 'DATE': {
        if (a === '' || a === undefined || a === null) {
          return 1
        }
        if (b === '' || b === undefined || b === null) {
          return -1
        }
        const da = new Date(a)
        const db = new Date(b)
        if (da < db) {
          return -1
        }
        if (da > db) {
          return 1
        }
        return 0
      }
      case 'NUMBER': {
        const na = a === '' 
          ? Number.MAX_VALUE : isNaN(Number(a)) ? Number.MAX_VALUE : Number(a)
        const nb = b === ''
          ? Number.MAX_VALUE : isNaN(Number(b)) ? Number.MAX_VALUE : Number(b)
        if (na < nb) {
          return -1
        } else if (nb < na) {
          return 1
        } else {
          return 0
        }
      }
    }
  }
  const sorted = rowsToSort.sort( (l1, l2) => {
    return sorting(l1[columnIndex], l2[columnIndex])
  })
  const newParsed: string[][] = [...uf.parsedData.slice(0, headers.line+1), ...sorted]
  return {...uf, parsedData: newParsed }
}