import * as React from 'react'
import { AppName, toolToColor } from '../Constants'
import {
  Link,
} from "react-router-dom"
import { State, Page} from '../Types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

type Props = {
  state: State<Page>
  updateState: (s: State<Page>) => void
}
const toColor = (p: Page) => {
  switch (p.kind) {
    case 'LANDINGPAGE': return 'black'
    case 'DATADROP': return 'black'
    case 'TABLE': return 'black'
    default: return toolToColor(p.kind)
  }
}
export const Header = (props: Props) => {
  const logostyle = {
    fontSize: '20px',
    fontFamily: 'Rubik Mono One'
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <Link to={"/"} style={{ textDecoration: 'none', color: 'black' }}>
        <div
          style={{
            marginTop: '20px',
            marginBottom: '20px'
          }}
          className={"logo-container"}
        >
          <span
            id={"logo"}
            style={logostyle}
          >
            {AppName}
          </span>
        </div>
      </Link>
      <div
        style={{
          flex: 1
        }}
      >
      </div>
      <div
        style={{
          marginRight: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {!props.state.sidemenuOpen
          && props.state.currentPage.kind !== 'LANDINGPAGE'
          && props.state.currentPage.kind !== 'DATADROP' ?
          (
            <div
              onClick={() => {
                props.updateState(({...props.state, sidemenuOpen: true }))
              }}
              style={{ cursor: 'pointer', position: 'relative'}}
            >
              {props.state.example === 'MOVE_TO_CONCAT'
              ? (
              <div
                style={{
                  fontSize: '20px',
                  fontWeight: 700,
                  top: '2px',
                  position: 'absolute',
                  left: '-250px',
                  color: toolToColor('SELECT')
                }}
              >
                Choose CONCAT tool ->
              </div>
              ) : null}
              <FontAwesomeIcon
                icon={faBars}
                size={'2x'}
                color={toColor(props.state.currentPage)}
              />
            </div>
          ) : null
        }
      </div>

    </div>

  )
}