import * as React from 'react'
import { Grid } from 'react-virtualized'
import { State, TablePage, Page } from '../Types'
import 'react-virtualized/styles.css' // only needs to be imported once
import { fireEvent } from '../libs/mixpanel'
import {
  Link,
} from "react-router-dom"

type Props = {
  state: State<TablePage>
  updateState: (s: State<Page>) => void
}

const cellRenderer = (columnWidth: number, props: Props, hl: boolean[][]) => ({ columnIndex, key, rowIndex, style }: any) => {
  const defaultStyle = {...style, width: `${columnWidth}px`, overflow: 'hidden'}
  const highlightStyle = {...defaultStyle, backgroundColor: 'lightGrey'}
  return (
    <div
      key={key}
      title={props.state.currentPage.userFile.parsedData[rowIndex][columnIndex]}
      style={(hl[columnIndex][rowIndex]) ? highlightStyle : defaultStyle}
    >
      {props.state.currentPage.userFile.parsedData[rowIndex][columnIndex]}
    </div>
  )
}
const onBackButtonEvent = (props: Props) => (e: any) => {
  e.preventDefault()
  if (typeof(props.state.prevPage) !== 'undefined') {
    props.updateState({
      currentPage: props.state.prevPage,
      prevPage: props.state.currentPage,
      sidemenuOpen: false
    })
  } else {
    props.updateState({
      currentPage: { kind: 'LANDINGPAGE'},
      prevPage: undefined,
      sidemenuOpen: false
    })
  }
}

export const TablePageComponent = (props: Props) => {
  window.onpopstate = onBackButtonEvent(props)
  fireEvent("View Page")
  const columnWidth = 120
  const rowHeight = 30
  const columnCount = props.state.currentPage.userFile.parsedData[0].length
  const rowCount = props.state.currentPage.userFile.parsedData.length
  const width = (columnWidth * columnCount)
  const height = rowHeight * rowCount
  const highlightArray = new Array(columnCount)
    .fill(null)
    .map(() => new Array(rowCount).fill(false))

  props.state.currentPage.highlights.forEach(h => {
    highlightArray[h.y][h.x] = true
  })

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'baseline',
          marginBottom: '50px',
          marginTop: '20px',
          marginLeft: '10px',
        }}
      >
        <div
          style={{
            fontSize: '24px',
            fontWeight: 700
          }}
        >{props.state.currentPage.userFile.name}</div>
        {typeof(props.state.prevPage) !== 'undefined' ?
          (
            <Link
              style={{
                textDecoration: 'none'
              }}
              to={`/${props.state.prevPage.kind.toLocaleLowerCase()}`}
            >
              <div
                style={{
                  color: props.state.currentPage.highlightColor,
                  fontSize: '18px',
                  fontWeight: 700,
                  marginBottom: 0,
                  marginTop: '15px',
                  marginLeft: '40px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  if (typeof(props.state.prevPage) !== 'undefined') {
                    props.updateState({
                      currentPage: props.state.prevPage,
                      prevPage: props.state.currentPage,
                      sidemenuOpen: false
                    })
                  }
                }}
              >
                Back
            </div>
          </Link>
          ) : null
        }
      </div>

      <Grid
        cellRenderer={
          cellRenderer(
            columnWidth,
            props,
            highlightArray,
          )
        }
        style={{
          border: '1px solid grey',
          marginLeft: '10px'
        }}
        columnCount={columnCount}
        columnWidth={columnWidth}
        height={height}
        rowCount={rowCount}
        rowHeight={rowHeight}
        width={width}
      />

    </div>
  )
}

