
import { Tool } from '../Types'
const onLocalhost = !(window.location.hostname.toLowerCase().search('localhost') < 0)

export type Event = 'Landing Page' 
  | "Data Drop"
  | "Read Excel"
  | "Read Image"
  | "Read CSV"
  | "Search Page"
  | "Concat Page"
  | "Split Page"
  | "To XSV Page"
  | "Download CSV"
  | "Download XLSX"
  | "Stats Page"
  | "Select Page"
  | "View Page"
  | "Example clicked"
  | "Example carrot written"
  | "Example moved to concat"
  | "Example concatenated files"
  | "Example download files"
  | "Merge Page"
  | "Sort Page"
  | "Groupby Page"
  | 'Copy Page'
  | 'Formula Page'

export const toolToEvent = (t: Tool): Event => {
  switch (t) {
    case 'SEARCH': return 'Search Page'
    case 'CONCAT': return 'Concat Page'
    case 'SELECT': return 'Select Page'
    case 'SPLIT': return 'Split Page'
    case 'STATS': return 'Stats Page'
    case 'TOXLSX': return 'To XSV Page'
    case 'MERGE': return 'Merge Page'
    case 'SORT': return 'Sort Page'
    case 'GROUPBY': return 'Groupby Page'
    case 'COPY': return 'Copy Page'
    case 'CALC': return 'Formula Page'
  }
}
  
export const fireEvent = (e: Event): void => {
  if (!onLocalhost) {
    // @ts-ignore
    mixpanel.track(e)
  }
}
