
import { UserFile, Headers } from '../Types'

export const headerIntersection = (uf: UserFile[]): string[] => {

  const headers: { [header: string]: number[]} = {}
  
  uf.forEach((f,k) => {
    
    if (f.parsedData.length > 0) {
      for (let i = 0; i < f.parsedData[0].length; ++i) {
        for (let j = 0; j < f.parsedData.length; ++j) {
          const v = f.parsedData[j][i]
          if (v !== '' && v !== undefined) {
            if (typeof(headers[v]) !== 'undefined') {
              headers[v].push(k)
            } else {
              headers[v] = [k]
            }
            break
          }
        }
      }
    }
  })

  const resultSet: string[] = []
  Object.keys(headers).forEach(k => {
    if (headers[k].length === uf.length) {
      resultSet.push(k)
    }
  })

  return resultSet
}

const checkComma = (s: string) => {
  if (typeof(s) !== 'string') {
    return true
  }
  if (s.length < 2) {
    return true
  }
  const a = s.slice(0,1)
  const b = s.slice(1,2)
  if (a === '0') {
    if (b === ',' || b === '.') {
      return true
    }
    return false
  }
  return true
}
const isNumber = (n: string) => !isNaN(Number(n)) && n !== null && n !== '' && checkComma(n)

export const getHeaders = (sheet: string[][]): Headers => {
  const lines: number[] = []
  if (sheet.length > 0) {
    for (let i = 0; i < sheet[0].length; ++i) {
      for (let j = 0; j < sheet.length; ++j) {
        const v = sheet[j][i]
        if (v !== '' && v !== undefined) {
          lines.push(j)
          break
        }
      }
    }
  }

  let mostCommon = -1
  let n = 0
  let current = -1
  let cn = 0
  lines.sort().forEach(s => {
    if (s !== current) {
      current = s
      cn = 1
    } else {
      cn += 1
    }
    if (cn > n) {
      n = cn
      mostCommon = current
    }
  })

  const result: Headers = {
    line: mostCommon,
    headers: [],
    getColumn: {},
    headerTypes: []
  }
  for (let i = 0; i < sheet[mostCommon].length; ++i) {
    result.headers[i] = sheet[mostCommon][i]
    result.getColumn[sheet[mostCommon][i]] = i
    // Get most common type for header type
    let strings = 0
    let numbers = 0
    for (let j = 0; j < sheet.length; ++j) {
      if (isNumber(sheet[j][i])) {
        numbers +=1
      } else {
        strings +=1 
      }
    }
    if (strings >= numbers) {
      result.headerTypes[i] = 'STRING'
    } else {
      result.headerTypes[i] = 'NUMBER'  
    }
  }
  return result
}

export const mergeSheets = (uf: UserFile[]): string[][] => {

  let resultSheet: string[][] = []
  const firstSheetHeaders = getHeaders(uf[0].parsedData)
  resultSheet[0] = ['Sheet', ...firstSheetHeaders.headers]
  let resultSheetIndex = 1
  uf.forEach((f,k) => {
    const sheet = f.parsedData
    const headers = getHeaders(f.parsedData)

    for (let j = headers.line + 1; j < sheet.length; ++j) {
      const newLine = [f.name]
      for (let i = 0; i < sheet[j].length; ++i) {
        const myHeader = headers.headers[i]
        let targetColumn = firstSheetHeaders.getColumn[myHeader]
        const data = sheet[j][i]
        if (typeof(targetColumn) === 'number') {
          newLine[targetColumn+1] = data
        } else {
          // Column doesnt exist create at the end 
          targetColumn = resultSheet[0].length
          resultSheet[0] = [...resultSheet[0], myHeader]
          // Add empty elements at the end
          for (let x = 1; x < resultSheet.length; ++x) {
            resultSheet[x][targetColumn] = ''
          }
          newLine[targetColumn] = data
          firstSheetHeaders.getColumn[myHeader] = targetColumn-1
        }
      }
      resultSheet[resultSheetIndex] = newLine
      resultSheetIndex += 1  
    }


  })

  // fix empty cell
  for (let j = 0; j < resultSheet.length; ++j) {
    for (let i = 0; i < resultSheet[j].length; ++i) {
      if (typeof(resultSheet[j][i]) === 'undefined') {
        resultSheet[j][i] = ''
      }
    }
  }
  return resultSheet
}