import React from 'react'
import { State, Page, UserFile, SelectPage, ToolPage } from '../../../Types'
import { ContentMaxWidth, toolToColor } from '../../../Constants'
import { Header } from '../../Header'
import { writeCsv } from '../../../libs/Download'
import './Select.css'
import { dropColumns } from '../../../libs/Select'
import {
  Link,
} from "react-router-dom"
import { MyDropzone } from '../DataDrop'

type Props = {
  updateState: (s: State<Page>) => void
  state: State<SelectPage>
}

const topContent = (props: Props) => {
  const headers = props.state.currentPage.headers
  const tool = props.state.currentPage.kind
  const somethingSelected = !Object.values(headers).every(a => a)
  return (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column'
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <div
        style={{
          fontSize: '22px',
          fontWeight: 700,
          color: 'grey',
          marginRight: '10px'
        }}
        >{"Pick headers you want to"}
      </div>
      <div
        style={{
          fontSize: '22px',
          fontWeight: 700,
          color: somethingSelected ? toolToColor(props.state.currentPage.kind) : 'grey',
          marginRight: '10px',
          cursor: somethingSelected ? 'pointer' : 'auto'
        }}
        onClick={() => {
          if (somethingSelected) {
            const newHeaders = {}
            Object.keys(headers).forEach(k => {
              if (headers[k]) {
                newHeaders[k] = true
              }
            })
            props.updateState({...props.state, currentPage: {
              ...props.state.currentPage,
              userFiles: dropColumns(
                props.state.currentPage.userFiles,
                props.state.currentPage.headers
              ),
              headers: newHeaders,
              undo: props.state.currentPage
            }})
          }
        }}
        >{"remove"}
      </div>
      <div
        style={{
          fontSize: '22px',
          fontWeight: 700,
          color: 'grey',
          marginRight: '10px'
        }}
        >{":"}
      </div>
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
      }}
    >
      {Object.keys(headers).map((h,key) => {
        return (
        <div
          key={key}
          className={"noselect"}
          style={{
            fontSize: '22px',
            fontWeight: 700,
            color: headers[h] ? toolToColor(tool) : 'grey',
            marginRight: '10px',
            cursor: 'pointer',
          }}
          onClick={() => {
            const nh = {...headers}
            nh[h] = !nh[h]
            props.updateState({
              ...props.state,
              currentPage: {
                ...props.state.currentPage,
                headers: nh
              }
            })
          }}
          >
          {h}
        </div>
        )
      })}
    </div>

  </div>)
}
export const Select = (props: Props) => {
  const undoable = typeof(props.state.currentPage.undo) !== 'undefined'
  const highlightColor = toolToColor(props.state.currentPage.kind)
  return (
    <div
      style={{
        width: ContentMaxWidth
      }}
    >
      <Header state={props.state} updateState={props.updateState} />
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '50px'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginBottom: '15px'
          }}
        >
          {topContent(props)}
          <div style={{flex: 1}} />
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end'
            }}
          >
            {undoable ? (
              [<div
                key={1}
                style={{
                  fontSize: '22px',
                  color: highlightColor,
                  fontWeight: 700,
                  marginLeft: '2px',
                  marginRight: '10px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  if (typeof(props.state.currentPage.undo) !== 'undefined') {
                    props.updateState(
                      {...props.state, currentPage: props.state.currentPage.undo}
                    )
                  }
                }}
              >
                Undo
              </div>,
              <div key={2} style={{
                borderRight: '1px solid',
                borderColor: 'grey',
                height: '35px'
              }}></div>
            ]
            ) : null }
            <div
              style={{
                fontSize: '22px',
                color: highlightColor,
                fontWeight: 700,
                marginLeft: '10px',
                cursor: 'pointer',
              }}
              onClick={() => {
                writeCsv(props.state.currentPage.userFiles)
              }}
            >
              Download
            </div>
          </div>
        </div>
        <span
          style={{
            width: '100%',
            borderBottom: '1px solid',
            borderColor: 'black'
          }}
        />
        {props.state.currentPage.userFiles.map((f: UserFile, key: number) => {
          return (
            <div
              key={key}
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%'
                }}
              >
                <input
                  type="text"
                  value={f.name}
                  onChange={(e) => {
                    props.updateState({
                      ...props.state,
                      currentPage: {
                        ...props.state.currentPage,
                        userFiles: [
                          ...props.state.currentPage.userFiles.slice(0,key),
                          {
                            ...props.state.currentPage.userFiles[key],
                            name: e.target.value
                          },
                        ...props.state.currentPage.userFiles.slice(key + 1,props.state.currentPage.userFiles.length)
                        ]
                      }
                    })
                  }}
                  placeholder={""}
                  style={{
                  width: '90%',
                  fontSize: '18px',
                  fontWeight: 700,
                  lineHeight: '38px',
                  border: 0,
                  outlineWidth: 0
                  }}
                >
                </input>
                <div style={{flex: 1}}></div>
                <Link
                  to={"/select/view"}
                  style={{ textDecoration: 'none' }}
                >
                  <div
                      onClick={() => {
                        props.updateState({
                          currentPage: {
                            kind: 'TABLE',
                            userFile: f,
                            highlights: [],
                            highlightColor: highlightColor
                          },
                          prevPage: props.state.currentPage,
                          sidemenuOpen: false
                        })
                      }}
                      style={{
                        marginTop: '0.83em',
                        marginBottom: '0.83em',
                        fontSize: '22px',
                        color: highlightColor,
                        fontWeight: 700,
                        cursor: 'pointer'
                      }}>
                        View
                    </div>
                  </Link>
              </div>
              <span
              style={{
                width: '100%',
                borderBottom: '1px solid',
                borderColor: 'grey'
              }}
              />
          </div>
          )
        })}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '80px',
          marginBottom: '80px'
        }}
      >
        <div
          style={{
            width: '600px',
            height: '450px'
          }}
        >
          <MyDropzone
            state={props.state}
            stateChange={(s: State<ToolPage>) => {
              props.updateState({
                ...props.state,
                currentPage: {
                  ...props.state.currentPage,
                  userFiles: [...props.state.currentPage.userFiles, ...s.currentPage.userFiles]
                }
              })
            }}
            textSize={'28px'}
            tool={'SELECT'}
          />
        </div>
      </div>
    </div>
  )
}