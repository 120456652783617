import React from 'react'
import { State, Page, UserFile, CopyPage, ToolPage } from '../../../Types'
import { ContentMaxWidth, toolToColor } from '../../../Constants'
import { Header } from '../../Header'
import { writeCsv } from '../../../libs/Download'

import {
  Link,
} from "react-router-dom"
import { copyToNewColumn } from 'src/libs/Copy'
import { MyDropzone } from '../../DataDrop/DataDrop'

type Props = {
  updateState: (s: State<Page>) => void
  state: State<CopyPage>
}

const topContent = (props: Props) => {
  return (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column'
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        marginBottom: '10px'
      }}
    >
      <div
        style={{
          fontSize: '22px',
          fontWeight: 700,
          width: '470px',
          color: 'grey',
          marginRight: '10px'
        }}
        >{"Give name for new column:"}
      </div>
      <input
          type="text"
          value={props.state.currentPage.columnName}
          onChange={(e) => {
            props.updateState({
              ...props.state,
              currentPage: {
                ...props.state.currentPage,
                columnName: e.target.value
              }
            })
          }}
          placeholder={''}
          style={{
           width: '90%',
           fontSize: '26px',
           fontWeight: 700,
           border: 0,
           borderBottom: 'solid',
           borderBottomWidth: '1px',
           outlineWidth: 0
          }}
        >

        </input>
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <div
        style={{
          fontSize: '22px',
          fontWeight: 700,
          color: 'grey',
          marginRight: '10px'
        }}
        >{"Remember to choose a column to copy from for each file!"}
      </div>
    </div>
  </div>)
}
export const Copy = (props: Props) => {
  const undoable = typeof(props.state.currentPage.undo) !== 'undefined'
  const highlightColor = toolToColor(props.state.currentPage.kind)
  return (
    <div
      style={{
        width: ContentMaxWidth
      }}
    >
      <Header state={props.state} updateState={props.updateState} />
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '50px'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginBottom: '15px'
          }}
        >
          {topContent(props)}
          <div style={{flex: 1}} />
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end'
            }}
          >
            {undoable ? (
              [<div
                key={1}
                style={{
                  fontSize: '22px',
                  color: highlightColor,
                  fontWeight: 700,
                  marginLeft: '2px',
                  marginRight: '10px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  if (typeof(props.state.currentPage.undo) !== 'undefined') {
                    props.updateState(
                      {...props.state, currentPage: props.state.currentPage.undo}
                    )
                  }
                }}
              >
                Undo
              </div>,
              <div key={2} style={{
                borderRight: '1px solid',
                borderColor: 'grey',
                height: '35px'
              }}></div>
            ]
            ) : ([<div
              key={1}
              style={{
                fontSize: '22px',
                color: highlightColor,
                fontWeight: 700,
                marginLeft: '2px',
                marginRight: '10px',
                cursor: 'pointer'
              }}
              onClick={() => {
                props.updateState({
                  ...props.state,
                  currentPage: {
                    ...props.state.currentPage,
                    userFiles: copyToNewColumn(
                      props.state.currentPage.columnName,
                      props.state.currentPage.userFiles,
                      props.state.currentPage.headerToCopy
                    ),
                    undo: props.state.currentPage
                  }
                })
              }}
            >
              Copy
            </div>,
            <div key={2} style={{
              borderRight: '1px solid',
              borderColor: 'grey',
              height: '35px'
            }}></div>
          ]) }
            <div
              style={{
                fontSize: '22px',
                color: highlightColor,
                fontWeight: 700,
                marginLeft: '10px',
                cursor: 'pointer',
              }}
              onClick={() => {
                writeCsv(props.state.currentPage.userFiles)
              }}
            >
              Download
            </div>
          </div>
        </div>
        <span
          style={{
            width: '100%',
            borderBottom: '1px solid',
            borderColor: 'black'
          }}
        />
        {props.state.currentPage.userFiles.map((f: UserFile, key: number) => {
          const headers = f.headers.headers

          return (
            <div
              key={key}
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%'
                }}
              >
                <input
                  type="text"
                  value={f.name}
                  onChange={(e) => {
                    props.updateState({
                      ...props.state,
                      currentPage: {
                        ...props.state.currentPage,
                        userFiles: [
                          ...props.state.currentPage.userFiles.slice(0,key),
                          {
                            ...props.state.currentPage.userFiles[key],
                            name: e.target.value
                          },
                        ...props.state.currentPage.userFiles.slice(key + 1,props.state.currentPage.userFiles.length)
                        ]
                      }
                    })
                  }}
                  placeholder={""}
                  style={{
                  width: '90%',
                  fontSize: '18px',
                  fontWeight: 700,
                  lineHeight: '38px',
                  border: 0,
                  outlineWidth: 0
                  }}
                >
                </input>
                <div style={{flex: 1}}></div>
                <Link
                  to={"/copy/view"}
                  style={{ textDecoration: 'none' }}
                >
                  <div
                      onClick={() => {
                        props.updateState({
                          currentPage: {
                            kind: 'TABLE',
                            userFile: f,
                            highlights: [],
                            highlightColor: highlightColor
                          },
                          prevPage: props.state.currentPage,
                          sidemenuOpen: false
                        })
                      }}
                      style={{
                        marginTop: '0.83em',
                        marginBottom: '0.83em',
                        fontSize: '22px',
                        color: highlightColor,
                        fontWeight: 700,
                        cursor: 'pointer'
                      }}>
                        View
                    </div>
                  </Link>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexFlow: 'wrap'
                }}
              >
                {headers.map((h,i) => {
                  const headersToCopy = props.state.currentPage.headerToCopy
                  const cur = headersToCopy[key]

                  return (
                    <div
                      key={i}
                      style={{
                        fontSize: '22px',
                        color: h === cur ? toolToColor(props.state.currentPage.kind) : 'grey',
                        marginRight: '10px',
                        cursor: 'pointer',
                        fontWeight: 700
                      }}
                      onClick={() => {
                        const newState = {
                          ...props.state,
                          currentPage: {
                            ...props.state.currentPage,
                            headerToCopy: [
                              ...headersToCopy.slice(0, key),
                              h,
                              ...headersToCopy.slice(key + 1, headersToCopy.length)
                            ]
                          }
                        }
                        props.updateState(newState)
                      }}
                    >
                    {h}
                    </div>
                  )
                })}
              </div>
              <span
              style={{
                width: '100%',
                borderBottom: '1px solid',
                borderColor: 'grey'
              }}
              />
          </div>
          )
        })}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '80px',
          marginBottom: '80px'
        }}
      >
        <div
          style={{
            width: '600px',
            height: '450px'
          }}
        >
          <MyDropzone
            state={props.state}
            stateChange={(s: State<ToolPage>) => {
              props.updateState({
                ...props.state,
                currentPage: {
                  ...props.state.currentPage,
                  userFiles: [...props.state.currentPage.userFiles, ...s.currentPage.userFiles]
                }
              })
            }}
            textSize={'28px'}
            tool={'COPY'}
          />
        </div>
      </div>
    </div>
  )
}