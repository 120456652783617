import * as React from 'react'
import { FrontPageCopyTexts, Email, ContentMaxWidth, toolToColor } from '../Constants'
// import .box:hover from css 
import './LandingPage.css'
import { State, Page, Tool, UserFile } from 'src/Types'
import { Header } from './Header'
import { filesToSA } from '../libs/substringArray'
import {
  Link,
} from "react-router-dom"

import { history } from '../App'
import { fireEvent } from 'src/libs/mixpanel'

type Box = {
  title: string
  bgcolor: string
  text: string
  tool: Tool
}
const firstRow: Box[] = [
  {
    title: 'Search',
    text: 'Search your files',
    bgcolor: toolToColor('SEARCH'),
    tool: 'SEARCH'
  },
  {
    title: 'Concat',
    text: 'Concatenate your files',
    bgcolor: toolToColor('CONCAT'),
    tool: 'CONCAT'
  },
  {
    title: 'To XLSX',
    text: 'Turn your files into an Excel workbook',
    bgcolor: toolToColor('TOXLSX'),
    tool: 'TOXLSX'
  },
  {
    title: 'Sort',
    text: 'Sort your spreadsheets by a column',
    bgcolor: toolToColor('SORT'),
    tool: 'SORT'
  }
]
const secondRow: Box[] = [
  {
    title: 'Split',
    text: 'Split your file into several files',
    bgcolor: toolToColor('SPLIT'),
    tool: 'SPLIT'
  },
  {
    title: 'Select',
    text: 'Select columns',
    bgcolor: toolToColor('SELECT'),
    tool: 'SELECT'
  },
  {
    title: 'Merge',
    text: 'Merge your spreadsheets',
    bgcolor: toolToColor('MERGE'),
    tool: 'MERGE'
  }
]

const thirdRow: Box[] = [
  {
    title: 'Statistics',
    text: 'Get simple statistics from your files',
    bgcolor: toolToColor('STATS'),
    tool: 'STATS'
  },
  {
    title: 'Group by',
    text: 'Group your data by a column',
    bgcolor: toolToColor('GROUPBY'),
    tool: 'GROUPBY'
  },
  {
    title: 'Formula',
    text: 'Run formula on a column',
    bgcolor: toolToColor('CALC'),
    tool: 'CALC'
  },
]
const fourthRow: Box[] = [
  {
    title: 'Copy',
    text: 'Copy values from one column to another',
    bgcolor: toolToColor('COPY'),
    tool: 'COPY'
  }
]

const h1Style = {
  margin: 0,
  width: '440px'
}

const h2Style = {
  margin: 0,
  fontWeight: 100
}


const boxh3Style = {
  marginLeft: '10px',
  marginRight: '10px',
  marginTop: '0px',
  marginBottom: '0px'
}
const boxpStyle = {
  marginLeft: '10px',
  marginRight: '10px'
}

const boxStyle = (color: string, active: boolean) => ({
  backgroundColor: color,
  display: 'flex',
  flexDirection: 'column' as 'column',
  justifyContent: 'center',
  cursor: active ? 'pointer' : 'none',
})

type Props = {
  state : State<Page>
  updateState: (s: State<Page>) => void
}

export const LandingPage = (props: Props) => {

  const updateState = (t: Tool) => () => {
    props.updateState({
      currentPage: {kind: 'DATADROP', tool: t},
      prevPage: {kind: 'LANDINGPAGE'},
      sidemenuOpen: false
    })
  }

  return (
    <div
      style={{
        width: ContentMaxWidth,
        height: '100%',
      }}
    >
      <Header state={props.state}
        updateState={props.updateState}
      />
      <div
        id="front-page-copy-texts"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '50px',
          marginBottom: '20px'
        }}
      >
        <h1
          style={h1Style}
        >
          {FrontPageCopyTexts.first}
        </h1>
        <h2
          style={h2Style}
        >
          {FrontPageCopyTexts.second}
        </h2>
        <h2
          style={h2Style}
        >
          {FrontPageCopyTexts.third}
        </h2>
        <div
          style={{
            fontSize: '28px',
            fontWeight: 700,
            color: toolToColor('SELECT'),
            cursor: 'pointer',
            marginTop: '10px'
          }}
          onClick={() => {
            fetch("/example.json")
            .then(res => res.json())
            .then((userFiles: UserFile[]) => {
              props.updateState(
                {
                  currentPage: { kind: 'SEARCH', userFiles: userFiles, SA: filesToSA(userFiles.map(f => f.parsedData))},
                  prevPage: props.state.currentPage,
                  sidemenuOpen: false,
                  example: 'SEARCH_CARROTS'
                }
              )
              history.push('/search')
              fireEvent('Example clicked')
            })
            .catch((er) => {
              // Ignore error on purpose
            })
          }}
        >
          Try example!
        </div>
      </div>
      <div
        style={{
          marginTop: '100px',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div
          className={"box-row-container"}
        >
          {firstRow.map((b,k) => (
            <Link
              key={k}
              to={`/${b.tool.toLocaleLowerCase()}`}
              style={{ textDecoration: 'none' }}
            >
              <div
                style={boxStyle(b.bgcolor, true)}
                className="box"
                onClick={updateState(b.tool)}
              >
                <h3
                  style={boxh3Style}
                >{b.title}</h3>
                <p style={boxpStyle}>{b.text}</p>
              </div>
            </Link>
          ))}
        </div>
        <div
          className={"box-row-container"}
        >
          {secondRow.map((b,k) =>
          (
            <Link
              key={k}
              to={`/${b.tool.toLocaleLowerCase()}`}
              style={{ textDecoration: 'none' }}
            >
            <div
              style={boxStyle(b.bgcolor, true)}
              className="box"
              onClick={updateState(b.tool)}
            >
              <h3
                style={boxh3Style}
              >{b.title}</h3>
              <p style={boxpStyle}>{b.text}</p>
            </div>
            </Link>
          ))}
        </div>
        <div
          className={"box-row-container"}
        >
        {thirdRow.map((b,k) => (
          <Link
            key={k}
            to={`/${b.tool.toLocaleLowerCase()}`}
            style={{ textDecoration: 'none' }}
          >
            <div
              style={boxStyle(b.bgcolor, true)}
              className="box"
              onClick={updateState(b.tool)}
            >
              <h3
                style={boxh3Style}
              >{b.title}</h3>
              <p style={boxpStyle}>{b.text}</p>
            </div>
          </Link>
        ))}
      </div>
      <div
        className={"box-row-container"}
      >
        {fourthRow.map((b,k) => (
          <Link
            key={k}
            to={`/${b.tool.toLocaleLowerCase()}`}
            style={{ textDecoration: 'none' }}
          >
            <div
              style={boxStyle(b.bgcolor, true)}
              className="box"
              onClick={updateState(b.tool)}
            >
              <h3
                style={boxh3Style}
              >{b.title}</h3>
              <p style={boxpStyle}>{b.text}</p>
            </div>
          </Link>
        ))}
      </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '100px',
          marginBottom: '20px'
        }}
      >
        <span
          style={{
            borderBottom: '0.5px',
            borderBottomStyle: 'solid',
            width: '100%',
            borderColor: 'grey'
          }}
        >
        </span>
        <div
          style={{
            color: 'grey',
            fontSize: '10px'
          }}
        >
          <span>This is still very much work in progress. If you want to share your opinions, bug reports or complaints feel free to email me at: <a style={{color: 'grey', textDecoration: 'none'}} href={"mailto:" + Email}>blocksheet.io@gmail.com</a></span>
            <span
              style={{
                float: 'right'
              }}
            >
              {process.env.REACT_APP_VERSION}
            </span>
          </div>
        </div>

    </div>
  )
}