import React from 'react'
import { State, Page, StatsPage, UserFile, ToolPage } from '../../../Types'
import { ContentMaxWidth, toolToColor } from '../../../Constants'
import { Header } from '../../Header'
import { writeCsv } from '../../../libs/Download'
import { statsToFile } from '../../../libs/Stats'
import {
  Link,
} from "react-router-dom"
import { MyDropzone } from '../DataDrop'

type Props = {
  updateState: (s: State<Page>) => void
  state: State<StatsPage>
}

export const Stats = (props: Props) => {
  const highlightColor = toolToColor(props.state.currentPage.kind)
  return (
    <div
      style={{
        width: ContentMaxWidth
      }}
    >
      <Header state={props.state} updateState={props.updateState} />
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '50px'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginBottom: '15px'
          }}
        >
          <div
            style={{
              fontSize: '22px',
              fontWeight: 700,
              color: 'grey'
            }}
          >{"Statistics with "}</div>
          <div
            style={{
              fontSize: '22px',
              color: highlightColor,
              fontWeight: 700,
              marginLeft: '4px',
              marginRight: '2px',
              cursor: 'pointer'
            }}
            onClick={() => {
              const h = !props.state.currentPage.header
              props.updateState({...props.state, currentPage: {
                ...props.state.currentPage,
                header: h,
                userFiles: props.state.currentPage.userFiles
                  .map(statsToFile(h))
              }})
            }}
          >
            {props.state.currentPage.header ? " header" : <del>{" header"}</del> }
          </div>
          <div
            style={{
              fontSize: '22px',
              fontWeight: 700,
              color: 'grey'
            }}
          >{". "}
          </div>
          <div style={{flex: 1}} />
          <div
            style={{
              fontSize: '22px',
              color: highlightColor,
              fontWeight: 700,
              marginLeft: '2px',
              cursor: 'pointer'
            }}
            onClick={() => {
              writeCsv(props.state.currentPage.userFiles)
            }}
          >
            Download
          </div>
        </div>
        <span
          style={{
            width: '100%',
            borderBottom: '1px solid',
            borderColor: 'black'
          }}
        />
        {
          props.state.currentPage.userFiles.map((file: UserFile, key) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '90%'
                }}
                key={key}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <div
                    style={{
                      fontSize: '18px',
                      display: 'flex',
                      alignItems: 'center',
                      fontWeight: 700,
                      lineHeight: '38px',
                      border: 0,
                      outlineWidth: 0
                    }}
                  >{file.name}</div>
                  <div
                    style={{
                      flex: 1
                    }}
                  ></div>
                  <Link
                    style={{ textDecoration: 'none' }}
                    to="/stats/view"
                  >
                    <div
                      onClick={() => {
                        props.updateState({
                          currentPage: {
                            kind: 'TABLE',
                            userFile: file,
                            highlights: [],
                            highlightColor: highlightColor
                          },
                          prevPage: props.state.currentPage,
                          sidemenuOpen: false
                        })
                      }}
                      style={{
                        marginTop: '0.83em',
                        marginBottom: '0.83em',
                        fontSize: '22px',
                        color: highlightColor,
                        fontWeight: 700,
                        cursor: 'pointer'
                      }}>
                        View
                    </div>
                  </Link>
                </div>
                <table
                  style={{
                    width: '100%'
                  }}
                >
                  <tbody>
                  <tr
                    key={-1}
                  >
                    <th style={{color: 'grey'}}>Column</th>
                    <th style={{color: 'grey'}}>Median</th>
                    <th style={{color: 'grey'}}>Mode</th>
                    <th style={{color: 'grey'}}>Longest</th>
                    <th style={{color: 'grey'}}>Shortest</th>
                    <th style={{color: 'grey'}}>Cardinality</th>


                  </tr>
                {file.stats.map((stat, key) => (
                      <tr
                        key={key}
                      >
                        <th
                          style={{
                            width: '5%'
                          }}
                        >
                          {typeof(stat.header) === 'undefined' ? key : stat.header}
                        </th>
                        <th
                          style={{
                            width: '5%'
                          }}
                        >
                          {stat.median}
                        </th>
                        <th
                          style={{
                            width: '5%'
                          }}
                        >
                          {stat.mode}
                        </th>
                        <th
                          style={{
                            width: '5%'
                          }}
                        >
                          {stat.longest}
                        </th>
                        <th
                          style={{
                            width: '5%'
                          }}
                        >
                          {stat.shortest}
                        </th>
                        <th
                          style={{
                            width: '5%'
                          }}
                        >
                          {stat.cardinality}
                        </th>
                      </tr>
                ))}
                </tbody>
                </table>

              </div>
            )
          })
        }
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '80px',
          marginBottom: '80px'
        }}
      >
        <div
          style={{
            width: '600px',
            height: '450px'
          }}
        >
          <MyDropzone
            state={props.state}
            stateChange={(s: State<ToolPage>) => {
              props.updateState({
                ...props.state,
                currentPage: {
                  ...props.state.currentPage,
                  userFiles: [...props.state.currentPage.userFiles, ...s.currentPage.userFiles]
                }
              })
            }}
            textSize={'28px'}
            tool={'STATS'}
          />
        </div>
      </div>
    </div>
  )
}